import { Injectable } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = '/assets/pdf.worker.min.mjs';

@Injectable({
  providedIn: 'root'
})
export class PdfThumbnailService {

  async generateThumbnail(pdfUrl: string): Promise<string> {
    // Carica il file PDF
    // console.log('pdfurl',pdfUrl)
    const pdf: pdfjsLib.PDFDocumentProxy = await pdfjsLib.getDocument(pdfUrl).promise;

    // Ottieni la prima pagina
    const page = await pdf.getPage(1);

    // Crea un canvas
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    const viewport = page.getViewport({ scale: 1 }); // Riduci la scala per una thumbnail
    canvas.width = viewport.width;
    canvas.height = viewport.height;

    // Renderizza la pagina nel canvas
    await page.render({
      canvasContext: context,
      viewport: viewport,
    }).promise;

    // Converti il canvas in data URL
    return canvas.toDataURL();
  }
}
