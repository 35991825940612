<div class="container-fluid">
    <div class="row innerTextFormat justify-content-start">
        <div class="col-12 text-center subtitle">
            Descrizione
        </div>
        <div *ngIf="analyticalData?.objectIndications" class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Indicazioni sull'Oggetto: </strong>
            {{analyticalData.objectIndications}}
        </div>
        <div *ngIf="analyticalData?.subjectIndication" class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Indicazioni sul Soggetto: </strong>
            <span *ngFor="let item of analyticalData?.subjectIndications; let i = index" class="me-1">
                {{item.subjectIndication}}<span *ngIf="i+1 < analyticalData.subjectIndication.length"></span>
            </span>
        </div>
    </div>

    <div *ngFor="let item of analyticalData?.subscriptions; let i = index" class="row innerTextFormat justify-content-start"
        style="border-top: 1px solid lightgray;">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Iscrizione {{i+1}}</span>
        </div>
        <div *ngIf="item?.membershipClass?.definition" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Classe di Appartenenza: </strong>
            {{item.membershipClass.definition}}
        </div>
        <div *ngIf="item?.language?.definition" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Lingua: </strong> {{item.language.definition}}
        </div>
        <div *ngIf="item?.technique" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Tecnica: </strong> {{item.technique}}
        </div>
        <div *ngIf="item?.charactersType?.definition" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Tipo di Caratteri: </strong> {{item.charactersType.definition}}
        </div>
        <div *ngIf="item?.author" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Autore: </strong> {{item.author}}
        </div>
        <div *ngIf="item?.location" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Posizione: </strong> {{item.location}}
        </div>
        <div *ngIf="item?.transcription" class="col-12 mb-1">
            <strong> Trascrizione: </strong> {{item.transcription}}
        </div>
    </div>

    <div *ngFor="let item of analyticalData?.emblems; let i = index" class="row innerTextFormat justify-content-start"
        style="border-top: 1px solid lightgray;">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Emblemi {{i+1}}</span>
        </div>
        <div *ngIf="item?.membershipClass?.definition" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Classe di Appartenenza: </strong>
            {{item.membershipClass.definition}}
        </div>
        <div *ngIf="item?.qualification?.definition" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Qualificazione: </strong> {{item.qualification.definition}}
        </div>
        <div *ngIf="item?.identification" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Identificazione: </strong> {{item.identification}}
        </div>
        <div *ngIf="item?.quantity" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Quantità: </strong> {{item.quantity}}
        </div>
        <div *ngIf="item?.location" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Posizione: </strong> {{item.location}}
        </div>
        <div *ngIf="item?.description" class="col-12 mb-1">
            <strong> Descrizione: </strong> {{item.description}}
        </div>
    </div>

    <div *ngIf="analyticalData?.historycalNews" class="row innerTextFormat" style="border-top: 1px solid lightgray">
        <div class="col-12 mb-1 pt-1">
            <strong> Notizie Storico-Critiche: </strong>
            {{analyticalData.historycalNews}}
        </div>
    </div>
</div>