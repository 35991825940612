<div class="container-fluid p-1 p-md-2 p-lg-3 p-xl-4" style="min-height: 84%; min-width: 350px; max-width: 1400px; margin-top: 66px;">
  <div class="row justify-content-center align-items-center p-2">
    <div class="col-12 text-center p-3">
      <span style="font-weight: xx-large; font-weight: bold;">IMPORTAZIONE DEFINIZIONI ARTISTICHE</span>
    </div>
    <div class="col-12">
      <table mat-table [dataSource]="dataSource$" class="mat-elevation-z8 demo-table">
        <!-- Position Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="tableName">
          <th mat-header-cell *matHeaderCellDef> Table Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- File Name Column -->
        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef> File Name </th>
          <td mat-cell *matCellDef="let element"> {{element.filename}} </td>
        </ng-container>

        <!-- Code Column -->
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef> Code </th>
          <td mat-cell *matCellDef="let element"> {{element.code}} </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="dateImport">
          <th mat-header-cell *matHeaderCellDef> Date Import </th>
          <td mat-cell *matCellDef="let element"> {{element.updated_at}} </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <input type="file" class="d-none" (change)="uploadFiles($event, element.name, element.id)" #fileUpload>
            <div class="file-upload" *ngIf="element.filename == null">
              <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
              Importa file Excel.
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
  </div>
</div>
