<div class="container p-1 p-md-2 p-lg-3" style="min-height: 90vh; min-width: 350px; max-width: 1400px; margin-top: 66px;">
    <div class="row p-2 justify-content-center align-items-center">
        <!-- INTESTAZIONE PAGINA -->
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 40%;">
                INVENTARIO ARTISTICO
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    INVENTARIO ARTISTICO
                </span>
            </div>
        </div>

        <!-- LISTA ARTICOLI -->
        <div *ngFor="let element of inventoryArtistic" class="col-12 col-md-10 pt-3">
            <div class="titleBorder ps-3 d-flex justify-content-between align-items-center"
                (click)="openClose(element)">
                <div class="d-flex align-items-center">
                    <i class="bi bi-folder me-2"></i>
                    <span class="category">{{element.title}}</span>
                    <i *ngIf="(element.isOpen && element.listArticles.length)" class="bi bi-caret-up-fill ms-2"></i>
                    <i *ngIf="(!element.isOpen && element.listArticles.length)" class="bi bi-caret-down-fill ms-2"></i>
                </div>
                <span class="me-2" style="color: gray; font-style: italic; font-size: small;">Totale:
                    {{element.listArticles.length}}</span>
            </div>
            <div [ngClass]="{'open': element.isOpen, 'closed': !element.isOpen}" class="content-wrapper row ps-3 pe-3 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5">
                <div *ngFor="let article of element.listArticles" class="col-12 contentBorder">
                    <button class="btn btn-link" (click)="goToArticle(article.id)">
                        <span>{{article.title}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>