<div class="container-fluid">
    <div *ngFor="let item of culturalDefinition?.author; let i = index"
        class="row innerTextFormat justify-content-center"
        [ngStyle]="{'border-top': i != 0 ? '1px solid lightgray' : 'none'}">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Autore {{i+1}}</span>
        </div>
        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Riferimento all'Autore: </strong> {{item.obj_author.definition}}
        </div>
        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Riferimento all'Intervento: </strong> {{item.obj_intervention.definition}}
        </div>
        <div class="col-12 mb-1">
            <strong> Motivazione dell'Attribuzione: </strong>
            <span *ngFor="let el of item.attributions; let i = index">
                {{el.definition}}<span *ngIf="i+1 < item.attributions.length">;</span>
            </span>
        </div>
    </div>

    <div *ngFor="let item of culturalDefinition?.scope; let i = index"
        class="row innerTextFormat justify-content-center" style="border-top: 1px solid lightgray">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Ambito Culturale {{i+1}}</span>
        </div>
        <div *ngIf="item?.obj_denomination?.definition" class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Denominazione: </strong> {{item.obj_denomination.definition}}
        </div>
        <div *ngIf="item?.obj_intervention?.definition" class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Riferimento all'Intervento: </strong> {{item.obj_intervention.definition}}
        </div>
        <div class="col-12 mb-1">
            <strong> Motivazione dell'Attribuzione: </strong>
            <span *ngFor="let el of item.attributions">
                {{el.definition}}<span *ngIf="i+1 < item.attributions.length">;</span>
            </span>
        </div>
    </div>

    <div class="row innerTextFormat justify-content-center" style="border-top: 1px solid lightgray;">
        <div class="col-12 mt-2">
            <strong> Altre Attribuzioni: </strong>
            <span *ngFor="let item of culturalDefinition?.othersAttributions; let i = index" class="me-1">
                <span *ngIf="item?.attributions">
                    {{item.attributions}}
                    <span *ngIf="i+1 < culturalDefinition.othersAttributions.length">;</span>
                </span>
            </span>
        </div>
    </div>

    <div *ngFor="let item of culturalDefinition?.client; let i = index"
        class="row innerTextFormat justify-content-center" style="border-top: 1px solid lightgray;">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Committenza {{i+1}}</span>
        </div>
        <div class="col-12 mb-1">
            <strong> Nome: </strong>
            <span *ngFor="let el of item.names" class="me-1">
                {{el.name}}<span *ngIf="i+1 < item.names.length">;</span>
            </span>
        </div>
        <div class="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 mb-1">
            <strong> Data: </strong> {{item.date}}
        </div>
        <div class="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4 mb-1">
            <strong> Circostanza: </strong> {{item.circumstance}}
        </div>
        <div class="col-12 col-xxl-4 mb-1">
            <strong> Fonte: </strong> {{item.source}}
        </div>
    </div>
</div>