<div class="container-fluid">
    <div class="container-fluid">
        <div class="row innerTextFormat justify-content-center"
            style="border-top: 1px solid lightgray">
            <div class="col-12 text-center subtitle">
                Acquisizione
            </div>
            <div *ngIf="legalCondition?.acquisitionType?.definition" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
                <strong> Tipo di Acquisizione: </strong>
                    {{legalCondition.acquisitionType.definition}} 
            </div>
            <div *ngIf="legalCondition?.name" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
                <strong> Nome: </strong>
                    {{legalCondition.name}} 
            </div>
            <div *ngIf="legalCondition?.acquisitionDate" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
                <strong> Data Acquisizione: </strong>
                    {{legalCondition.acquisitionDate}} 
            </div>
            <div *ngIf="legalCondition?.acquisitionLocation" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
                <strong> Luogo Acquisizione: </strong>
                    {{legalCondition.acquisitionLocation}} 
            </div>
        </div>
    </div>
</div>