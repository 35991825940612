import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chronology-readonly',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './chronology-readonly.component.html',
  styleUrl: './chronology-readonly.component.scss'
})
export class ChronologyReadonlyComponent {

  @Input() chronologyData: any = null;

}
