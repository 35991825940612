import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-general-info-readonly',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './general-info-readonly.component.html',
  styleUrl: './general-info-readonly.component.scss'
})
export class GeneralInfoReadonlyComponent {

  @Input() generalInfo: any = null;

  constructor() {}

}
