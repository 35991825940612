<div class="container-fluid">
    <div class="row innerTextFormat justify-content-center">
        <div class="col-12 text-center subtitle">
            Ambito Culturale
        </div>
        <div *ngIf="conservationData?.conservationStatus?.definition" class="col-12 mb-1">
            <strong> Stato di Conservazione: </strong>
                {{conservationData.conservationStatus.definition}} 
        </div>
        <div *ngIf="conservationData?.specificIndications" class="col-12 mb-1">
            <strong> Indicazioni Specifiche: </strong>
                {{conservationData.specificIndications}} 
        </div>
    </div>
</div>