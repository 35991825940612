<div class="container-fluid">
    <div *ngFor="let item of chronologyData; let i = index" class="row innerTextFormat justify-content-center"
        [ngStyle]="{'border-top': i != 0 ? '1px solid lightgray' : 'none'}">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Cronologia {{i+1}}</span>
        </div>
        <div class="col-12 col-sm-6 mb-1">
            <strong> Secolo: </strong> {{item.century}}
        </div>
        <div *ngIf="item?.centuryFraction?.definition" class="col-12 col-sm-6 mb-1">
            <strong> Frazione di Secolo: </strong> {{item.centuryFraction.definition}}
        </div>
        <div class="col-6 col-md-3 col-lg-6 col-xl-6 col-xxl-2 mb-1">
            <strong> Da: </strong> {{item.from}}
        </div>
        <div *ngIf="item?.validity_from?.definition" class="col-6 col-md-3 col-lg-6 col-xl-6 col-xxl-4 mb-1">
            <strong> Validità: </strong> {{item.validity_from.definition}}
        </div>
        <div class="col-6 col-md-3 col-lg-6 col-xl-6 col-xxl-2 mb-1">
            <strong> A: </strong> {{item.to}}
        </div>
        <div *ngIf="item?.validity_to?.definition" class="col-6 col-md-3 col-lg-6 col-xl-6 col-xxl-4 mb-1">
            <strong> Validità: </strong> {{item.validity_to.definition}}
        </div>
        <div class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Motivazione Cronologica: </strong> 
            <span *ngFor="let el of item.chronology_reason; let i = index">
                {{el.definition}}<span *ngIf="i+1 < item.chronology_reason.length">;</span>
            </span>
        </div>
        <div class="col-12 col-md-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Altre Datazioni: </strong> 
            <span *ngFor="let el of item.otherDates; let i = index">
                {{el.date}}<span *ngIf="i+1 < item.otherDates.length">;</span>
            </span>
        </div>
    </div>
</div>