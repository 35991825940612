<div class="container p-1 p-md-2 p-lg-3" style="min-height: 80%; min-width: 350px; max-width: 1400px; margin-top: 66px;">
    <div class="row align-items-center justify-content-center p-1 p-sm-2" style="margin-left: 0 !important; margin-right: 0 !important;">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 45%;">
                CHI SIAMO
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    CHI SIAMO
                </span>   
            </div>
        </div>
        <div class="col-12 mt-4" style="max-width: 900px;">
            <p>
            <h3 style="font-weight: bold; border-left: 5px solid #1E73BE; padding-left: 10px;">ASSOCIAZIONE</h3>
            <p>
                La Misericordia di Borgo San Lorenzo ha oltre 170 anni di storia, é nata infatti il 19 marzo 1847. <br>
                Da
                sempre inserita nel tessuto sociale della propria Comunittà, pronta al servizio dei fratelli del
                territorio del Comune di Borgo San Lorenzo e del Mugello. <br>
                Oggi siamo oltre 1.500 iscritti (tra Borgo San Lorenzo e la sezione di Luco) divisi nei vari “ruoli”
                occupati da donne e da uomini, indistintamente:
            </p>
        </div>
        <div class="row justify-content-center text-center" style="max-width: 900px;">
            <div class="col-12">
                <p style="font-weight: bold;">
                    Conservatori, Conservatori emeriti, Capi di Guardia Attivi, Capi di Guardia in soprannumero, Capi di
                    Guardia Onorari,
                    Giornanti Attivi, Giornanti in soprannumero, Aggregati Attivi, Aggregati Semplici, Aspiranti.
                </p>
            </div>
        </div>
        <div class="col-12" style="max-width: 900px;">
            <p>
            <h3 style="font-weight: bold; border-left: 5px solid #1E73BE; padding-left: 10px;">STORIA</h3>
            <p>
                <strong>1847 – Fondazione </strong><br>
                Nella foto: I primi incarichi assegnati dalla Confraternita. Centosettanta’anni di vita non si
                raccontano in poco spazio: ci limitiamo qui a riassumerli, facendo cenno ai fatti più memorabili. A
                cominciare dalla nascita: “Correndo poi l’anno 1846… istituivasi in Borgo sotto il titolo di San
                Sebastiano, la Confraternita della Misericordia, i cui capitoli vennero approvati dalla Segreteria del
                Regio Diritto il 19 Giugno, dalla Curia Arcivescovile Fiorentina il 26 Agosto di detto anno,
                inaugurandosi poi solennemente nel 19 Marzo 1847″. <br>
                I suoi fondatori si erano ispirati certo alla Misericordia ‘madre’, nata nel 1244 a Firenze, ma avevano
                anche ripreso una vecchia tradizione caritativa borghigiana, che a partire dal 1481 aveva visto l’antica
                Compagnia de’ Neri espletare per molti anni le opere di misericordia verso i bisognosi in genere. La
                sede iniziale della nuova compagnia consisteva in tre stanze prese in affitto: così si legge nel Verbale
                di Adunanza del Magistrato del 12 Febbraio 1848: “… Essendo conosciuto necessario di stabilire … la
                locazione delle stanze che servono per la custodia dei malati … fu deliberato … di fare la locazione
                delle stanze suddette per tre anni con la pigione di lire 80 l’anno”. Non disponendo di un proprio
                oratorio chiesero e ottennero ospitalità dalla Compagnia di Sant’Omobono.
            </p>
            <p>
                <strong>1849 – La Compagnia di Sant’Omobono</strong><br>

                Nel 1849, anche in segno di gratitudine, il Consiglio accolse una domanda della Compagnia di
                Sant’Omobono per far restaurare il proprio Oratorio: “Sentita l’istanza presentata dalla Compagnia di
                Sant’Omobono con la quale si domandava un sussidio per supplire alle spese necessarie per riparare
                l’Oratorio suddetto e considerando che il detto Oratorio serva anco per l’uso della nostra
                Confraternita, e che perciò è giusto di supplire a questa spesa, girato il Partito fu deciso dì sborsare
                al detto Oratorio per detto titolo la somma di scudi 50”. Così i confratelli con le caratteristiche
                cappe nere e la buffa sul volto, cominciarono la loro opera al servizio del paese. “Dio ve ne renda
                merito!” era la moneta che pagava le loro fatiche. Nel 1851 la confraternita ereditò da un sacerdote, un
                edificio in Sant’Andrea (oggi Corso Matteotti): l’acquisizione di una sede propria permise ai
                confratelli di essere più organizzati nello svolgimento dei servizi e di rispondere meglio alle
                richieste di aiuto.
            </p>
            <p>
                <strong>1855 – Il Colera</strong> <br>
                Nel Luglio 1855, il colera mieté vittime a centinaia anche nel Mugello: “Né la nascente Confraternita
                della Misericordia di Borgo San Lorenzo … recò poco vantaggio e poco sollievo a’ poveri colpiti dal
                morbo”. Si riunì il Consiglio per una importante delibera: “Essendo venuto il tempo in cui la
                confraternita può dimostrare col fatto quale è stato e quale deve essere il fine della sua istituzione,
                di prestarsi cioè al soccorso della popolazione nella circostanza attuale in cui domina o tende a
                dominare il morbo asiatico … devono essere costantemente reperibili 16 individui, anche estranei alla
                compagnia i quali, al semplice invito del Servo, devono portarsi immediatamente alle stanze e, col
                cataletto andare a prender il malato per trasportarlo al Lazzaretto situato alle Conce presso il Ponte
                della Sieve”. Sempre in quegli anni, i fratelli iniziarono a compiere opera di assistenza ai reclusi del
                carcere locale. Ed ancora nella sede di Via Sant’Andrea la Misericordia organizzò una specie di
                Ospedale, ove non solo si curavano le malattie, ma anche si cercava di alleviare la miseria e la fame
                che torturavano molti borghigiani.
            </p>
            <p>
                <strong>1871 – L’Ospedale nel Mugello</strong> <br>
                Quando la campana, detta proprio Misericordia, suonava dal campanile della Pieve, alla sede era un
                accorrere di fratelli per soccorrere, per consolare, per pregare, e, dal 1875, anche per trasportare i
                defunti al Cimitero che la Misericordia aveva aperto nella zona di Ripa. Quando nel 1871 venne aperto
                l’Ospedale del Mugello a Luco, i misericordiosi, rendendosi conto che il volantino (una lettiga a mano
                su ruote, tipo barroccino!), non era più adatto a trasportare i malati, decisero di dotarsi di un carro
                di volata, cioè una lettiga trainata da un cavallo, tale da consentire un trasporto più veloce e viaggi
                più lunghi, anche a Firenze. Via via con il passar degli anni cambiavano le esigenze e la Misericordia
                si adeguava.
            </p>
            <p>
                <strong>1900 – La Squadra Ciclistica di Pronto Intervento</strong> <br>
                Eravamo così agli inizi del nostro secolo, il 1900. Si arrivò perfino ad organizzare una squadra
                ciclistica di pronto intervento! La Misericordia continuava a crescere: i vecchi locali in Sant’Andrea
                non bastavano più ed allora, a prezzo di grossi sacrifici, si dette il via alla costruzione della nuova
                sede, dotata non solo di rimesse per i mezzi di soccorso e di locali per la cura dei mali fisici, ma
                anche e soprattutto di un locale, l’oratorio, ove riunirsi in preghiera prima e dopo i servizi di
                carità, perché questo era lo spirito che giustificava l’opera dei confratelli, e cioè fare il bene del
                prossimo nel nome di Cristo.
            </p>
            <p>
                <strong>1911 – Il Dolore di Borgo</strong><br>
                Quanti capitoli di dolore nella storia di Borgo e quindi della sua Misericordia! 1911: nuova epidemia di
                colera … poi la ‘spagnola’, altra grave pestilenza che portò alla tomba tanti concittadini.
            </p>
            <p>
                <strong>1915 – La Prima Guerra Mondiale</strong><br>
                E siamo alla Prima Guerra Mondiale, 1915-1918: oltre a … portare via tanti fratelli, la guerra chiese
                alla Misericordia di Borgo anche un aiuto pratico; squadre di misericordiosi si recavano a turno alla
                stazione ferroviaria, ove passavano i treni-ospedale e le tradotte provenienti dal fronte con i feriti
                di guerra e davano la loro opera di soccorso e di conforto.
            </p>
            <p>
                <strong>1919 – Il terremoto</strong><br>
                Poi il 1919: il terremoto ‘batte’ con violenza nel Mugello, provocando lutti e rovine, ed ancora la
                Misericordia è lì, pronta a porgere il suo aiuto.
            </p>
            <p>
                <strong>1920 – Il disastro ferroviario di Ronta</strong><br>
                Nel 1920 un disastro ferroviario a Ronta: ancora la Misericordia a soccorrere i feriti e recuperare i
                morti.
            </p>
            <p>
                <strong>1924 – La prima Autolettiga</strong><br>
                Nel 1924 una grande … rivoluzione: si inaugura la prima autolettiga, che gradatamente sostituirà il
                carro a cavalli. Si trattava di una FIAT 507 a due letti, che di lì a qualche anno fu affiancata da una
                seconda vettura più moderna, una FIAT 1500 a un letto. In quegli anni l’attività del sodalizio era
                divenuta sempre più intensa tanto che questo era diventato una vera istituzione nell’ambito del paese.
                L’impegno della Misericordia contribuiva notevolmente a garantire soccorso e assistenza ai bisognosi:
                “Le sue opere di carità consistono nel trasporto degli ammalati o feriti agli ospedali, nelle visite
                agli infermi, nelle mutature di letto, nell’assistenza ai degenti nelle stanze di pronto soccorso, nel
                trasporto dei defunti al Camposanto della Misericordia e nei sussidi ai miserabili in occasione della
                festa di San Sebastiano”.
            </p>
            <p>
                <strong>1930 – Il Poliambulatorio</strong> <br>
                Grande impulso, nel periodo fra il ’30 e il ’40, ebbe il poliambulatorio, ove addirittura si eseguivano
                interventi chirurgici sia pure semplici come la tonsillectomia e le adenoidi.
            </p>
            <p>
                <strong>1941 – La Seconda Guerra Mondiale</strong> <br>
                1941: l’Italia è coinvolta da un anno nella terribile Seconda Guerra Mondiale. Ancora i confratelli
                debbono lasciare la Misericordia e partire soldati. Ma lo spirito di carità cristiana, proprio allora fa
                nascere un’altra bellissima iniziativa: sorge il gruppo dei donatori di sangue. 1943, anno dei primo,
                tremendo bombardamento aereo su Borgo: un centinaio di morti, feriti innumerevoli, dolore, disperazione
                … La Misericordia è sempre lì, in prima linea, con i suoi ‘fratelli’, con le sue vesti nere, con le sue
                ambulanze e con i poliambulatori e perfino con i carri-lettiga del 1800 ripescati nel museo, per
                soccorrere al meglio in tutto quel disastro. E i morti da ricomporre, da allineare in ogni locale della
                sede e poi da trasportare ai cimiteri.
            </p>
            <p>
                <strong>1945 – Il fronte passa nel Mugello</strong>
                1945: il fronte è appena passato nel nostro Mugello. La vita di Borgo ricomincia lentamente, pur fra le
                rovine, i lutti, la miseria. Una delle prime cose che riprendono a funzionare è proprio la
                Misericordia.” La sede è danneggiata dalle esplosioni, le ambulanze sono … scomparse assieme ai tedeschi
                in ritirata; molti ‘fratelli’ non sono tornati dai vari fronti … Eppure lo spirito di carità cristiana è
                rimasto lo stesso di sempre, anzi sembra che la tragedia lo abbia ravvivato. Così riprendono anche i
                servizi di trasporto dei malati, con un’ambulanza… residuo di guerra, insomma un Gippone!
            </p>
            <p>
                <strong>1947 – Primo Secolo di Vita</strong><br>
                Eccoci al 1947: la Misericordia di Borgo compie un secolo di vita e si sta riprendendo e potenziando a
                vista d’occhio. Anche le ‘sorelle’ iniziano a fare servizio attivo: quanto preziose quelle nottate ai
                malati! Fra il ’50 e il ’60 è una crescita continua nell’organizzazione e nei servizi: il gabinetto
                radiologico, il poliambulatorio che riprende, il gruppo donatori di sangue che si riorganizza e si
                ingrandisce, l’ambulanza che viene via via sostituita sempre con mezzi più adeguati, l’arrivo del
                telefono alla sede. Tappe di uno sviluppo continuo. Le autoambulanze, che dal 1964 tornano ad essere
                due, cominciano ad arrivare con gli equipaggi vestiti in cappa bianca, ma lo spirito del servizio resta
                lo stesso di sempre: “Beati miscricordes…” aveva detto Cristo nel Discorso della Montagna… Si
                organizzava in quegli anni anche una prima forma di protezione civile, in grado di intervenire anche in
                altre zone (alluvione del Polesine e di Firenze, terrermoto in Sicilia, poi nel Friuli, … ). Siamo ormai
                nel tempo più vicino a noi e la Misericordia continua il suo cammino di carità a favore della
                popolazione.
            </p>
            <p>
                <strong>1970 – Assistenza Domiciliare</strong><br>
                Ed ecco allora, fra il ’70 e l’80, l’assistenza domiciliare, il servizio di radio ricetrasmittenti, i
                turni fissi in sede anche la notte; ecco il servizio di onoranze funebri; ecco le ambulanze più moderne
                ed attrezzate (la ‘culla termica’, il ‘cardiotel’, la barella ‘a cucchiaio’ …). Ecco il servizio in sede
                per le iniezioni, ed ecco, fiore all’occhiello, la Guardia Medica Pediatrica.
            </p>
            <p>
                <strong>1979 – La Sezione di Bivigliano</strong><br>
                La vecchia pianta centenaria dà ancora i suoi virgulti: nel ’79 nasce la sezione di Bivigliano (che in
                seguito diventerà autonoma).
            </p>
            <p>
                <strong>1983 – Il Centralino radio-telefonico</strong><br>
                nel 1983 nasce il centralino radio-telefonico e si istituisce il coordinamento fra le Misericordie del
                Mugello. E poi, pian piano, ecco le più recenti tappe: arriva il turno di Guardia Medica, poi quello
                dell’emergenza col medico sull’ambulanza. Nasce la sezione Luco-Grezzano; nasce il servizio di
                ‘tele-soccorso’ per gli anziani; poi una modernissima apparecchiatura per la radiologia e per
                l’ecografia. E la Misericordia di Borgo è presente sempre più spesso nelle varie zone d’Italia dove il
                dolore chiama: Irpinia … San Benedetto Val di Sambro … Piemonte … Non sono più le vesti nere, ora sono
                le casacche a colori vivaci, le divise quasi da sala operatoria, le attrezzature moderne. Ma resta
                ancora qualcosa, ed è da augurarsi, dell’antico spirito. Ecco, questa è ovviamente un’elencazione dei
                fatti, di date, di iniziative. Ma sono questi fatti, queste date che ci danno la misura della vitalità
                di un’associazione di volontariato qual è la Misericordia di Borgo San Lorenzo; un’associazione che non
                mostra certo il peso di 150 anni d’età, ma che, oseremmo dire, è ancor più viva di quando fu fondata.
                Anche perché la sua storia è ormai radicata e compenetrata nella storia di Borgo ed è anche per questo
                che, dopo un secolo e mezzo, la Misericordia borghigiana si accinge a proseguire il suo cammino di
                carità cristiana verso altri traguardi, altri anniversari, sempre al servizio della popolazione, con lo
                stesso spirito del 1847.
            </p>
        </div>
        <div class="col-12 mt-4 text-center">
            <img src="assets/img/personale.jpg" style="max-width: 50%; height: auto;">
        </div>
    </div>
</div>