import { CommonModule } from '@angular/common';
import { Component, signal, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectOptions } from '../../interfaces/select-options';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { Connect } from '../../../../classes/connect';
import { ApiResponse } from '../../../../interfaces/api-response';

@Component({
  selector: 'app-conservation',
  standalone: true,
  imports: [
    MatExpansionModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './conservation.component.html',
  styleUrl: './conservation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConservationComponent {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;

  saved: boolean = false;
  submitted = false;

  @Input() statusOptions: SelectOptions[] | null = null;

  conservationForm: FormGroup;

  ngOnInit(): void {
    this.getConservationInfo();
  }

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    this.conservationForm = this.fb.group({
      conservationStatus: [null, Validators.required],
      specificIndications: [null, Validators.maxLength(500)]
    });
  }

  private getConservationInfo() {
    this.connectServerService.getRequest('conservationInfo', { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ conservationForm: any }>) => {
          this.conservationForm.patchValue(val.data.conservationForm);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.conservationForm);
          if (this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  private setConservationInfo() {
    const formValues = this.conservationForm.getRawValue();
    this.connectServerService.postRequest('updateConservationInfo',
      { idarticle: this.idarticle, infoConservation: formValues })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getConservationInfo();
        }
      )
  }

  save() {
    this.submitted = true;
    if (this.conservationForm.valid) {
      this.setConservationInfo();
    }
  }

}
