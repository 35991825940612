<nav class="navbar fixed-top navbar-expand-lg border-bottom-3"
  style="background-color:#1E73BE; opacity: 0.95; min-width: 350px; z-index: 2000;">
  <div class="container-fluid">
    <img src="assets/img/logo.png" style="cursor: pointer; height: 50px;" (click)="navigate('home')">
    <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- MENU NAVBAR -->
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <li *ngFor="let menu of (menuNavbar$ | async)" class="nav-item d-flex align-items-center mb-1">
          <a *ngIf="!menu.submenu; else dropdownBlock" class="nav-link" (click)="navigate(menu.path)" style="color: white; font-weight: bold;
                            text-decoration: solid; text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; cursor: pointer;" 
            [ngStyle]="getMenuStyles(menu.name)">
            {{menu.name}}
          </a>
          <!-- ELEMENTI A TENDINA -->
          <ng-template #dropdownBlock>
            <!-- ELEMENTI A TENDINA IN FORMATO ESPANSO -->
            <div class="dropdown d-none d-lg-block">
              <button class="btn btnDropdown dropdown-toggle pe-1 me-0" type="button" data-bs-toggle="dropdown"
                aria-expanded="false"
                [ngStyle]="{'border-bottom': isCurrentPage(menu.name) ? '4px solid white' : 'none'}">
                <span style="color: white; font-weight: bold; text-decoration: solid;
                                    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
                  {{menu.name}}
                </span>
              </button>
              <ul class="dropdown-menu expandedDropdown" style="padding-top: 0.2rem; padding-bottom: 0.2rem; background-color: #1E73BE;
                                border: 1px solid lightgray;">
                <li *ngFor="let submenu of menu.submenu" 
                  [ngClass]="{'borderBottom': lastSubmenuVoice(submenu.order!, menu.submenu!)}">
                  <a class="dropdown-item text-center ps-0 pe-0 submenuList" style="cursor: pointer;" (click)="navigate(submenu.path)">
                    <span
                      style="color: white; text-decoration: solid; text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
                      {{submenu.name}}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <!-- ELEMENTI A TENDINA IN FORMATO RIDOTTO -->
            <div class="d-block d-lg-none d-flex flex-column">
              <div class="dropdown-toggle btnDropdown d-flex align-items-center" data-bs-toggle="dropdown"
                aria-expanded="false" style="height: 40px; cursor: pointer;"
                [ngStyle]="{'border-bottom': isCurrentPage(menu.name) ? '4px solid white' : 'none'}">
                <span style="color: white; font-weight: bold; text-decoration: solid;
                                    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
                  {{menu.name}}
                </span>
              </div>
              <ul class="dropdown-menu mt-0 pt-0"
                style="padding-top: 0.2rem; padding-bottom: 0.2rem; background-color: #1E73BE; border: none;">
                <li *ngFor="let submenu of menu.submenu">
                  <a class="dropdown-item text-start ps-3 pt-0 pb-1 pe-0 submenuList" style="cursor: pointer;" (click)="navigate(submenu.path)">
                    <span
                      style="color: white; text-decoration: solid; text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
                      {{submenu.name}}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </ng-template>
        </li>
      </ul>
      <div *ngIf="authService.isLoggedIn()" class="d-none d-lg-block">
        <!-- <button *ngIf="!authService.isLoggedIn()" (click)="navigate('/login')" type="button"
                    class="btn btn-outline-light d-flex align-items-center buttonStyle">
                    <i class="bi bi-person-fill iconStyle"></i>
                    <a class="nav-link">Login</a>
                </button> -->
        <div class="dropdown">
          <button *ngIf="user$ | async as user; else loading" type="button"
            class="btn btn-outline-light d-flex align-items-center buttonStyle dropdown-toggle" type="button"
            data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-person-fill iconStyle"></i>
            <span style="padding-right: 5px;">{{user.name}}</span>
          </button>

          <ul class="dropdown-menu custom-dropdown" style="z-index: 1000;">
            <!-- <li class="dropdown-item text-start profileList" style="width: 110px;"
                            (click)="goToPermissions()">
                            <i class="bi bi-check-all"></i>
                            Permessi
                        </li> -->
            <li *ngFor="let menuUser of (menuUserNavbar$ | async)" class="dropdown-item text-start profileList"
              style="width: 110px;" (click)="navigate(menuUser.path)">
              <!-- <i class="bi bi-gear-fill me-1"></i> -->
              {{menuUser.name}}
            </li>
            <li class="dropdown-item text-start profileList" style="width: 110px;" (click)="logout()">
              <i class="bi bi-box-arrow-right me-1" style="margin-inline-start: 2px;"></i>
              Esci
            </li>
          </ul>
          <ng-template #loading>
            <button type="button" class="btn btn-outline-light d-flex align-items-center buttonStyle">
              <span>Loading...</span>
            </button>
          </ng-template>
        </div>
      </div>
      <div *ngIf="authService.isLoggedIn()" class="d-block d-lg-none d-flex flex-column text-start">
        <!-- <div *ngIf="!authService.isLoggedIn()" class="d-flex align-items-center mb-2 mt-1">
                    <a class="nav-link" href="login">
                        <span style="color: white; font-weight: bold; text-decoration: solid;
                                    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
                            Login
                        </span>
                    </a>
                </div> -->

        <!-- <div class="d-flex align-items-center text-start" style="height: 40px;">
                        <a class="nav-link cursor" (click)="goToPermissions()"
                            [ngStyle]="getLinkStyles('Autorizzazioni')">
                            <span style="color: white; font-weight: bold; text-decoration: solid;
                                        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
                                Permessi
                            </span>
                        </a>
                    </div> -->
        <div class="d-flex align-items-center text-start" style="height: 43px;">
          <a class="nav-link cursorPointer" style="cursor: pointer;" (click)="navigate('settings')" [ngStyle]="getLinkStyles('Settings')">
            <span style="color: white; font-weight: bold; text-decoration: solid;
                                        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
              Settings
            </span>
          </a>
        </div>
        <div class="d-flex align-items-center" style="height: 43px;">
          <a class="nav-link logout" style="cursor: pointer;" (click)="logout()">
            <span style="color: white; font-weight: bold; text-decoration: solid;
                                        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
              Logout
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
