import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectOptions } from '../../interfaces/select-options';
import { Connect } from '../../../../classes/connect';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { ApiResponse } from '../../../../interfaces/api-response';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';
import { SelectDataAnalyticalData } from '../../interfaces/select-data-analytical-data';

@Component({
  selector: 'app-analytical-data',
  standalone: true,
  imports: [
    MatExpansionModule,
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './analytical-data.component.html',
  styleUrl: './analytical-data.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalyticalDataComponent {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;

  saved: boolean = false;
  submitted = false;

  analyticalForm: FormGroup;

  @Input() listAnalyicalData: SelectDataAnalyticalData | null = null;


  membershipClassSub: SelectOptions[] = [];

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    this.analyticalForm = this.fb.group({
      objectIndications: [null, [Validators.required, Validators.maxLength(1300)]],
      subjectIndication: this.fb.array([this.createSubjectIndicationsForm()]),
      subscriptions: this.fb.array([this.createSubscriptionsForm()]),
      emblems: this.fb.array([this.createEmblemsForm()]),
      historycalNews: [null, Validators.maxLength(5000)]
    });
  }

  ngOnInit(): void {
    //TEMPORANEO
    this.getAnalyticalDataInfo();
  }

  // Funzione per ottenere il FormArray 'subscriptions'
  get subscriptions(): FormArray {
    return this.analyticalForm.get('subscriptions') as FormArray;
  }

  // Funzione per ottenere il FormArray 'emblems' per un chronology specifico
  get emblems(): FormArray {
    return this.analyticalForm.get('emblems') as FormArray;
  }

  get subjectIndications(): FormArray {
    return this.analyticalForm.get('subjectIndication') as FormArray;
  }

  // Crea un singolo form emblemi
  createEmblemsForm(): FormGroup {
    return this.fb.group({
      id: [0],
      membershipClass: [null],
      qualification: [null],
      identification: [null, Validators.maxLength(100)],
      quantity: [null, Validators.maxLength(10)],
      location: [null, Validators.maxLength(100)],
      description: [null, Validators.maxLength(500)]
    });
  }

  // Crea un singolo form subscription
  createSubscriptionsForm(): FormGroup {
    return this.fb.group({
      id: [0],
      membershipClass: [null],
      language: [null],
      technique: [null],
      charactersType: [null],
      location: [null, Validators.maxLength(100)],
      author: [null, Validators.maxLength(100)],
      transcription: [null, Validators.maxLength(10000)]
    });
  }

  createSubjectIndicationsForm(): FormGroup {
    return this.fb.group({
      id: [0],
      subjectIndication: [null, [Validators.required, Validators.maxLength(2000)]],
    })
  }

  // Aggiungi una nuova subscriptions form
  addSubscriptions() {
    this.subscriptions.push(this.createSubscriptionsForm());
  }

  // Aggiungi un nuovo form emblemi
  addEmblems() {
    this.emblems.push(this.createEmblemsForm());
  }

  addSubjectIndications() {
    this.subjectIndications.push(this.createSubjectIndicationsForm());
  }

  // Rimuovi un form, ma lascia almeno uno
  removeSubscriptions(index: number) {
    if (this.subscriptions.length > 1) {
      this.subscriptions.removeAt(index);
    }
  }

  removeEmblems(index: number) {
    if (this.emblems.length > 1) {
      this.emblems.removeAt(index);
    }
  }

  removeSubjectIndications(index: number) {
    if (this.subjectIndications.length > 1) {
      this.subjectIndications.removeAt(index);
    }
  }

  private getAnalyticalDataInfo() {
    this.connectServerService.getRequest('analyticalData', { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ analyticalForm: any }>) => {
          this.analyticalForm.patchValue(val.data.analyticalForm);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.analyticalForm);
          if(this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  private setAnalyticalDataInfo() {
    const formValues = this.analyticalForm.getRawValue();
    // console.log(formValues);
    this.connectServerService.postRequest('updateAnalyticalData',
      { idarticle: this.idarticle, infoGeneral: formValues })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getAnalyticalDataInfo();
        }
      )
  }

  save() {
    this.submitted = true;
    if(this.analyticalForm.valid) {
      this.setAnalyticalDataInfo();
    }
  }

}
