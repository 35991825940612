import { Component, HostListener } from '@angular/core';
import { ApiResponse } from '../../../interfaces/api-response';
import { Connect } from '../../../classes/connect';
import { ArticleFile } from '../../../interfaces/image';
import { PopupDialogService } from '../../../services/popup-dialog.service';
import { ConnectServerService } from '../../../services/connect-server.service';
import { AuthService } from '../../../services/auth.service';
import { ArticlesService } from '../../../services/articles.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ArticleArtisticICCD } from '../../../interfaces/article-artistic';
import { MatTooltip } from '@angular/material/tooltip';
import { PdfDisplayComponent } from '../components/pdf-display/pdf-display.component';
import { ImgDisplayComponent } from '../components/img-display/img-display.component';
import { PdfThumbnailService } from '../../../services/pdf-thumbnail.service';
import { GeneralInfoReadonlyComponent } from "./general-info-readonly/general-info-readonly.component";
import { ObjectReadonlyComponent } from "./object-readonly/object-readonly.component";
import { LocationDataReadonlyComponent } from "./location-data-readonly/location-data-readonly.component";
import { ChronologyReadonlyComponent } from "./chronology-readonly/chronology-readonly.component";
import { CulturalDefinitionComponent } from "../article-artistic-cards/cultural-definition/cultural-definition.component";
import { CulturalDefinitionReadonlyComponent } from "./cultural-definition-readonly/cultural-definition-readonly.component";
import { TechnicalDataComponent } from "../article-artistic-cards/technical-data/technical-data.component";
import { TechnicalDataReadonlyComponent } from "./technical-data-readonly/technical-data-readonly.component";
import { ConservationReadonlyComponent } from "./conservation-readonly/conservation-readonly.component";
import { AnalyticalDataReadonlyComponent } from "./analytical-data-readonly/analytical-data-readonly.component";
import { LegalConditionReadonlyComponent } from "./legal-condition-readonly/legal-condition-readonly.component";
import { SourcesReadonlyComponent } from "./sources-readonly/sources-readonly.component";

@Component({
  selector: 'app-article-artistic',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatTooltip,
    GeneralInfoReadonlyComponent,
    ObjectReadonlyComponent,
    LocationDataReadonlyComponent,
    ChronologyReadonlyComponent,
    CulturalDefinitionReadonlyComponent,
    TechnicalDataReadonlyComponent,
    ConservationReadonlyComponent,
    AnalyticalDataReadonlyComponent,
    LegalConditionReadonlyComponent,
    SourcesReadonlyComponent
],
  templateUrl: './article-artistic.component.html',
  styleUrl: './article-artistic.component.scss'
})
export class ArticleArtisticComponent {
  urlServerLaraApiMultimedia = Connect.urlServerLaraApiMultimedia;
  files: ArticleFile[] = [];
  thumbnails: { [key: string]: string } = {};

  showModal: boolean = false;
  modalImageUrl: string = '';
  articleData: { dimension: string, imgFormat: string, weigth: number, id: number } = { dimension: '', imgFormat: '', weigth: 0, id: 0 };
  id: number = 0;
  article!: ArticleArtisticICCD | null;
  currentImg: number = 0;

  constructor(private route: ActivatedRoute, public articlesService: ArticlesService, private router: Router,
    private viewportRuler: ViewportRuler, public authService: AuthService,
    public dialog: MatDialog, public popupDialogService: PopupDialogService, private connectServerService: ConnectServerService,
    private pdfThumbnailService: PdfThumbnailService) { }

  ngOnInit(): void {
    this.checkWindowSize();
    this.initialize();
  }

  openFullscreenModal(articleFile: ArticleFile): void {

    const id = articleFile.id;
    if (id > 0) {
      if (articleFile.ext == "pdf") {
        this.dialog.open(PdfDisplayComponent, {
          data: { article: articleFile },
          panelClass: 'fullscreen-modal',
          width: '90vh',
          height: '90vh',
          maxHeight: '90vh',
          maxWidth: '95vw',
          minWidth: '250px',
          position: {
            top: '70px',
          },
          // autoFocus: false
        });
      }
      else {
        this.dialog.open(ImgDisplayComponent, {
          data: { article: articleFile },
        });
      }
    }
  }

  initialize() {
    this.route.params.subscribe(
      (params) => {
        //console.error('params: ', params['name'])
        this.id = params['id'];
        this.articlesService.getArticleArtisticFromServer(this.id).
          subscribe((val: ApiResponse<{ article: ArticleArtisticICCD }>) => {
            this.article = val.data.article;
            // console.log("Articolo:", this.article)
          });
        //this.articleData = this.articlesService.getArticleData(this.id);
        this.articlesService.getFilesServer(this.id, 1).
          subscribe((val: ApiResponse<{ listFiles: ArticleFile[] }>) => {
            if (val.data.listFiles) {
              this.files = val.data.listFiles;
              this.generatePdfThumbnails();
              // console.log(this.files)
            }
          });
      }
    );

  }

  async generatePdfThumbnails(): Promise<void> {
    for (const file of this.files) {
      if (file.ext === 'pdf') {
        const thumbnail = await this.pdfThumbnailService.generateThumbnail(this.urlServerLaraApiMultimedia + file.src);
        this.thumbnails[file.src] = thumbnail;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkWindowSize();
  }

  navigate(id: number) {
    this.router.navigate(['/archivioArtistico/scheda/imageviewer', id])
  }

  // getBlurredImage(): string {
  //   return this.article?.src[this.currentImg]!;
  // }

  checkWindowSize() {
    const screenWidth = this.viewportRuler.getViewportSize().width;
    if (screenWidth <= 768) {
      this.showModal = false;
    }
    else {
      this.showModal = true;
    }
  }

  setModalContent(imageUrl: string) {
    this.modalImageUrl = imageUrl;
  }

  nextImg() {
    //if(this.article?.src.length! > this.currentImg + 1) {
    if (this.files.length > this.currentImg + 1) {
      this.currentImg += 1;
    }
    //else if(this.article?.src.length === this.currentImg + 1)
    else if (this.files.length === this.currentImg + 1) {
      this.currentImg = 0;
    }
    //console.log("current img:", this.currentImg);
  }

  prevImg() {
    if (this.currentImg > 0) {
      this.currentImg -= 1;
    }
    else if (this.currentImg === 0) {
      this.currentImg = this.files.length - 1;
    }
    //console.log("current img:", this.currentImg);
  }

  goToImg(i: number) {
    this.currentImg = i;
  }

  goBack() {
    //this.router.navigate(['/archivioArtistico']);
    window.history.back();
  }

  deleteArticle(id: number) {
    const obj_request: ApiResponse<any> = {
      code: 244,
      data: {},
      title: 'Info',
      message: 'Sei sicuro di voler cancellare l\'immagine?',
      obj_dialog: {
        disableClose: 0,
        obj_buttonAction:
        {
          action: 1,
          action_type: 2,
          label: 'Cancella',
          run_function: () => this.deleteArticleFromServer(id)
        }
      }
    }
    this.popupDialogService.popupDialog(obj_request);
  }

  private deleteArticleFromServer(id: number) {
    this.connectServerService.postRequest<ApiResponse<any>>(
      'articleObsolete', { idarticle: id })
      .subscribe((val: any) => {
        this.popupDialogService.popupDialog(val);
        this.router.navigate(["archivioArtistico"]);
      })
  }

}
