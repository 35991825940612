<div class="mt-2 mb-2">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header class="borderBottom">
                <mat-panel-title><span class="title">Dati Patrimoniali</span></mat-panel-title>
                <mat-panel-description class="justify-content-end">
                    <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
                    <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="locationForm">
                    <div class="row justify-content-start">
                        <div class="col-12 mt-2 text-center">
                            <span class="subTitle">Ubicazione</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-4">
                            <label for="original_location" class="form-label" style="font-weight: bold;">
                                Ubicazione Originaria
                            </label>
                            <select class="form-select mb-2" aria-label="Default select example"
                                formControlName="original_location" id="original_location" 
                                [class.is-invalid]="locationForm.get('original_location')?.invalid && 
                                (submitted || locationForm.get('original_location')?.dirty)">
                                <option [ngValue]="null">Non specificato</option>
                                <option *ngFor="let option of listLocation" [ngValue]="option.id" class="form-control">
                                    {{option.definition}}
                                </option>
                            </select>
                        </div>

                    </div>

                    <div class="row justify-content-start borderTop pb-2 mt-2"
                        style="border: 1px solid lightgray; border-radius: 10px;">
                        <div class="col-12 mt-2 text-center">
                            <span class="subTitle">Inventario di Museo o Soprintendenza</span>
                        </div>

                        <div formArrayName="museumInventory">
                            <div *ngFor="let inventory of museumInventory.controls; let i = index" [formGroupName]="i"
                                class="row justify-content-start">
                                <div class="col-12" *ngIf="i != 0"
                                    style="border-top: 1px solid lightgray; margin-top: 1rem;">
                                    <!-- Rimuovi -->
                                    <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2"
                                        (click)="removeMuseumInventory(i)" [disabled]="museumInventory.length === 1">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                    <label style="font-weight: bold;">Inventario {{i + 1}}</label><br>
                                </div>

                                <div class="col-12 col-md-4">
                                    <label for="number-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">Numero<sup>*</sup></label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="number-{{i}}"
                                            formControlName="number"
                                            [class.is-invalid]="museumInventory.at(i).get('number')?.invalid && (submitted || museumInventory.at(i).get('number')?.dirty)">
                                    </div>
                                    <div
                                        *ngIf="museumInventory.at(i).get('number')?.errors?.['maxlength'] && museumInventory.at(i).get('number')?.dirty">
                                        <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <label for="date-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">Data<sup>*</sup></label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="date-{{i}}" formControlName="date"
                                            [class.is-invalid]="museumInventory.at(i).get('date')?.invalid && (submitted || museumInventory.at(i).get('date')?.dirty)">
                                    </div>
                                    <div
                                        *ngIf="museumInventory.at(i).get('date')?.errors?.['maxlength'] && museumInventory.at(i).get('date')?.dirty">
                                        <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                                    </div>
                                </div>

                                <div class="col-12 col-md-4">
                                    <label for="location-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">Collocazione</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="location-{{i}}"
                                            formControlName="location"
                                            [class.is-invalid]="museumInventory.at(i).get('location')?.invalid && (submitted || museumInventory.at(i).get('location')?.touched)">
                                    </div>
                                    <div
                                        *ngIf="museumInventory.at(i).get('location')?.errors?.['maxlength'] && museumInventory.at(i).get('location')?.touched">
                                        <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- Aggiungi nuovo gruppo di campi -->
                    <div class="row justify-content-center">
                        <button class="btn btn-outline-dark mt-1 text-center" style="max-width: 200px;"
                            (click)="addMuseumInventory()">
                            <span class="me-2">Aggiungi inventario</span>
                            <i class="bi bi-plus"></i>
                        </button>
                    </div>
                </form>

                <div class="row justify-content-center mt-4 borderTop">
                    <div *ngIf="locationForm.invalid && submitted" class="col-12 mt-2 text-center">
                        <small>Compilare correttamente tutti i campi obbligatori per abilitare il
                            salvataggio
                        </small>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
                        <button class="btn btn-outline-dark"
                            (click)="save()">Salva</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>