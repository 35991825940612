<div class="mt-2 mb-2">
  <mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)"
      (closed)="panelOpenState.set(false)">
      <mat-expansion-panel-header class="borderBottom">
        <mat-panel-title><span class="title">Fonti e Documenti di Riferimento</span></mat-panel-title>
        <mat-panel-description class="justify-content-end">
          <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
          <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="container-fluid p-1 p-lg-2">
        <form [formGroup]="sourcesForm">
          <div formArrayName="picturesSources">
            <div *ngFor="let inventory of picturesSources.controls; let i = index" [formGroupName]="i"
              style="border: 1px solid lightgray; border-radius: 10px; position: relative;"
              class="pb-2 mt-2 row justify-content-start">
              <div *ngIf="i != 0" class="col-12 removeButton">
                <!-- Rimuovi -->
                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2" (click)="removePicturesSources(i)"
                  [disabled]="picturesSources.length === 1">
                  <i class="bi bi-trash"></i>
                </button>
                <label class="d-none d-lg-inline" style="font-weight: bold;">Documentazione Fotografica {{i +
                  1}}</label><br>
              </div>
              <div class="col-12 mt-2 text-center">
                <span class="subTitle">Documentazione Fotografica</span>
              </div>


              <div class="col-12 col-md-6">
                <label for="owner-{{i}}" class="form-label mt-3" style="font-weight: bold;">Ente
                  proprietario
                </label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="owner-{{i}}" formControlName="owner"
                    [class.is-invalid]="inventory.get('owner')?.invalid && (inventory.get('owner')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('owner')?.invalid && inventory.get('owner')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <label for="code-{{i}}" class="form-label mt-3" style="font-weight: bold;">Codice
                  identificativo<sup>*</sup></label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="code-{{i}}" formControlName="code"
                    [class.is-invalid]="inventory.get('code')?.invalid && (inventory.get('code')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('code')?.invalid && inventory.get('code')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 25 caratteri</span>
                </div>
              </div>
            </div>

            <!-- Aggiungi nuovo gruppo di campi -->
            <div class="col-12 mt-1 text-center">
              <button class="btn btn-outline-dark mb-2" style="max-width: 350px;" (click)="addPicturesSources()">
                <span class="me-2">Aggiungi Documentazione Fotografica</span>
                <i class="bi bi-plus"></i>
              </button>
            </div>
          </div>

          <div formArrayName="documentSources">
            <div *ngFor="let inventory of documentSources.controls; let i = index" [formGroupName]="i" class="row"
              style="border: 1px solid lightgray; border-radius: 10px; position: relative;"
              class="pb-2 mt-2 row justify-content-start">
              <div *ngIf="i != 0" class="col-12 removeButton">
                <!-- Rimuovi -->
                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2" (click)="removeDocumentSources(i)"
                  [disabled]="documentSources.length === 1">
                  <i class="bi bi-trash"></i>
                </button>
                <label class="d-none d-md-inline" style="font-weight: bold;">Fonti e Documenti {{i + 1}}</label><br>
              </div>
              <div class="col-12 mt-2 mb-3 text-center">
                <span class="subTitle">Fonti e Documenti</span>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="type-{{i}}" class="form-label mt-3" style="font-weight: bold;">Tipo<sup>*</sup></label>
                <select class="form-select" id="type-{{i}}" aria-label="Default select example" formControlName="type"
                  [class.is-invalid]="inventory.get('type')?.invalid && (inventory.get('type')?.dirty || submitted)">
                  <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                  <option *ngFor="let option of listSourcesData?.listTypeSource" [ngValue]="option.id" class="form-control">
                    {{option.definition}}
                  </option>
                </select>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="author1-{{i}}" class="form-label mt-3" style="font-weight: bold;">Autore</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="author1-{{i}}" formControlName="author"
                    [class.is-invalid]="inventory.get('author')?.invalid && (inventory.get('author')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('author')?.invalid && inventory.get('author')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="denomination-{{i}}" class="form-label mt-3" style="font-weight: bold;">Denominazione</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="denomination-{{i}}"
                    formControlName="denomination"
                    [class.is-invalid]="inventory.get('denomination')?.invalid && (inventory.get('denomination')?.dirty || submitted)">
                </div>
                <div
                  *ngIf="inventory.get('denomination')?.invalid && (inventory.get('denomination')?.dirty || submitted)">
                  <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="date-{{i}}" class="form-label mt-3" style="font-weight: bold;">Data</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="date-{{i}}" formControlName="date"
                    [class.is-invalid]="inventory.get('date')?.invalid && (inventory.get('date')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('date')?.invalid && inventory.get('date')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 25 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="paper-{{i}}" class="form-label mt-3" style="font-weight: bold;">Foglio/Carta</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="paper-{{i}}" formControlName="paper"
                    [class.is-invalid]="inventory.get('paper')?.invalid && (inventory.get('paper')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('paper')?.invalid && inventory.get('paper')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 25 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="archiveName-{{i}}" class="form-label mt-3" style="font-weight: bold;">Nome
                  Archivio</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="archiveName-{{i}}"
                    formControlName="archiveName"
                    [class.is-invalid]="inventory.get('archiveName')?.invalid && (inventory.get('archiveName')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('archiveName')?.invalid && inventory.get('archiveName')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="position-{{i}}" class="form-label mt-3" style="font-weight: bold;">Posizione</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="position-{{i}}" formControlName="position"
                    [class.is-invalid]="inventory.get('position')?.invalid && (inventory.get('position')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('position')?.invalid && inventory.get('position')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                </div>
              </div>
            </div>

            <!-- Aggiungi nuovo gruppo di campi -->
            <div class="col-12 mt-1 text-center">
              <button class="btn btn-outline-dark mb-2" style="max-width: 300px;" (click)="addDocumentSources()">
                <span class="me-2">Aggiungi Fonti e Documenti</span>
                <i class="bi bi-plus"></i>
              </button>
            </div>
          </div>

          <div formArrayName="bibliography">
            <div *ngFor="let inventory of bibliography.controls; let i = index" [formGroupName]="i"
              style="border: 1px solid lightgray; border-radius: 10px; position: relative"
              class="row justify-content-start pb-2 mt-2">
              <div *ngIf="i != 0" class="col-12 removeButton">
                <!-- Rimuovi -->
                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2" (click)="removeBibliography(i)"
                  [disabled]="bibliography.length === 1">
                  <i class="bi bi-trash"></i>
                </button>
                <label class="d-none d-md-inline" style="font-weight: bold;">Bibliografia {{i + 1}}</label><br>
              </div>
              <div class="col-12 mt-2 mb-3 text-center">
                <span class="subTitle">Bibliografia</span>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="genre-{{i}}" class="form-label mt-3" style="font-weight: bold;">Genere<sup>*</sup></label>
                <select class="form-select" id="genre-{{i}}" aria-label="Default select example" formControlName="genre"
                  [class.is-invalid]="inventory.get('genre')?.invalid && (inventory.get('genre')?.dirty || submitted)">
                  <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                  <option *ngFor="let option of listSourcesData?.listGenreSource" [ngValue]="option.id" class="form-control">
                    {{option.definition}}
                  </option>
                </select>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="author2-{{i}}" class="form-label mt-3" style="font-weight: bold;">Autore</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="author2-{{i}}" formControlName="author"
                    [class.is-invalid]="inventory.get('author')?.invalid && (inventory.get('author')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('author')?.invalid && inventory.get('author')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="year-{{i}}" class="form-label mt-3" style="font-weight: bold;">Anno di
                  edizione</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="year-{{i}}" formControlName="year"
                    [class.is-invalid]="inventory.get('year')?.invalid && (inventory.get('year')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('year')?.invalid && inventory.get('year')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 10 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="acronym-{{i}}" class="form-label mt-3" style="font-weight: bold;">Sigla
                  per citazione</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="acronym-{{i}}" formControlName="acronym"
                    [class.is-invalid]="inventory.get('acronym')?.invalid && (inventory.get('acronym')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('acronym')?.invalid && inventory.get('acronym')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 8 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="vPpNn-{{i}}" class="form-label mt-3" style="font-weight: bold;">V. pp.
                  nn.</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="vPpNn-{{i}}" formControlName="vPpNn"
                    [class.is-invalid]="inventory.get('vPpNn')?.invalid && (inventory.get('vPpNn')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('vPpNn')?.invalid && inventory.get('vPpNn')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-4">
                <label for="vTavvFigg-{{i}}" class="form-label mt-3" style="font-weight: bold;">V.
                  tavv. figg.</label>
                <div class="input-group" style="height: 40px;">
                  <input type="text" class="form-control" placeholder="" id="vTavvFigg-{{i}}"
                    formControlName="vTavvFigg"
                    [class.is-invalid]="inventory.get('vTavvFigg')?.invalid && (inventory.get('vTavvFigg')?.dirty || submitted)">
                </div>
                <div *ngIf="inventory.get('vTavvFigg')?.invalid && inventory.get('vTavvFigg')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                </div>
              </div>
            </div>

            <!-- Aggiungi nuovo gruppo di campi -->
            <div class="col-12 mt-1 text-center">
              <button class="btn btn-outline-dark mb-2" style="max-width: 300px;" (click)="addBibliography()">
                <span class="me-2">Aggiungi Bibliografia</span>
                <i class="bi bi-plus"></i>
              </button>
            </div>
          </div>


          <div formArrayName="completeCitations">
            <div class="row justify-content-start pb-2" style="position: relative;">
              <label class="form-label mt-3" style="font-weight: bold;">
                Citazione completa
              </label>
              <div *ngFor="let inventory of completeCitations.controls; let i = index" [formGroupName]="i"
                class="col-12 col-md-6 col-lg-4 mt-1">
                <div class="col-12 col-md-6 input-group">
                  <input type="text" class="form-control" id="completeCitation-{{i}}" formControlName="completeCitation"
                    [class.is-invalid]="inventory.get('completeCitation')?.invalid && (inventory.get('completeCitation')?.dirty || submitted)">
                  <button *ngIf="i != 0" type="button" class="btn btn-danger" (click)="removeBibliography(i)">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
                <div *ngIf="inventory.get('completeCitation')?.invalid && inventory.get('completeCitation')?.dirty">
                  <span style="font-size: x-small; color: red;">Max 500 caratteri</span>
                </div>
              </div>
            </div>

            <!-- Aggiungi nuovo gruppo di campi -->
            <div class="col-12 mt-1 text-start pb-2">
              <button class="btn btn-outline-dark" style="max-width: 300px;" (click)="addCompleteCitations()">
                <span class="me-2">Aggiungi Citazione Completa</span>
                <i class="bi bi-plus"></i>
              </button>
            </div>
          </div>
        </form>


        <div class="row justify-content-center mt-4 borderTop">
          <div *ngIf="sourcesForm.invalid && submitted" class="col-12 mt-2 text-center">
            <small>Compilare correttamente tutti i campi obbligatori per abilitare il
              salvataggio
            </small>
          </div>
          <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
            <button class="btn btn-outline-dark" (click)="save()">Salva</button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
