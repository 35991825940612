<div class="mt-2 mb-2">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header>
                <mat-panel-title><span class="title">Dati Analitici</span></mat-panel-title>
                <mat-panel-description class="justify-content-end">
                    <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
                    <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="analyticalForm">
                    <div class="row justify-content-start pb-2 mt-2">
                        <div class="col-12 mt-2 text-center">
                            <span class="subTitle">Descrizione</span>
                        </div>

                        <!-- Campo objectIndications -->
                        <div class="col-12">
                            <label for="objectIndications" class="form-label mt-3"
                                style="font-weight: bold;">indicazioni sull'oggetto<sup>*</sup></label>
                            <div class="input-group">
                                <textarea type="text" class="form-control" id="objectIndications"
                                    formControlName="objectIndications"
                                    [class.is-invalid]="analyticalForm.get('objectIndications')?.invalid && (analyticalForm.get('objectIndications')?.dirty || submitted)">
                                </textarea>
                            </div>
                            <div
                                *ngIf="analyticalForm.get('objectIndications')?.invalid && analyticalForm.get('objectIndications')?.dirty">
                                <span style="font-size: x-small; color: red;">Max 1300 caratteri</span>
                            </div>
                        </div>

                        <!-- Campo subjectIndication -->
                        <div formArrayName="subjectIndication">
                            <div *ngFor="let item of subjectIndications.controls; let i = index" [formGroupName]="i"
                                class="col-12">
                                <label for="subjectIndication-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Indicazioni sul soggetto<sup>*</sup></label>
                                <div class="input-group">
                                    <textarea type="text" class="form-control" id="subjectIndication-{{i}}"
                                        formControlName="subjectIndication"
                                        [class.is-invalid]="item.get('subjectIndication')?.invalid && (item.get('subjectIndication')?.dirty || submitted)">
                                    </textarea>
                                    <button *ngIf="i != 0" class="btn btn-sm btn-danger"
                                        (click)="removeSubjectIndications(i)">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                </div>
                                <div
                                    *ngIf="item.get('subjectIndication')?.invalid && item.get('subjectIndication')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 2000 caratteri</span>
                                </div>
                            </div>
                        </div>
                        <!-- Aggiungi nuovo gruppo di campi -->
                        <div class="col-12 mt-1 text-start">
                            <button class="btn btn-outline-dark" (click)="addSubjectIndications()">
                                <span class="me-2">Aggiungi Indicazioni Soggetto</span>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div formArrayName="subscriptions">
                        <div *ngFor="let chronology of subscriptions.controls; let i = index" [formGroupName]="i"
                            style="border: 1px solid lightgray; border-radius: 10px; position: relative;"
                            class="row justify-content-start pb-2 mt-2">

                            <div *ngIf="i != 0" class="col-12 removeButton">
                                <!-- Rimuovi -->
                                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2"
                                    (click)="removeSubscriptions(i)" [disabled]="subscriptions.length === 1">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <label class="d-none d-md-inline" style="font-weight: bold;">Iscrizioni {{i + 1}}</label><br>
                            </div>
                            <div class="col-12 mt-2 text-center" style="padding-left: 0;">
                                <span class="subTitle">Iscrizioni</span>
                            </div>

                            <!-- Campo Classe di appartenenza -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="membershipClass1-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Classe di appartenenza</label>
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="membershipClass" id="membershipClass1-{{i}}">
                                    <option [ngValue]="null">Non specificato</option>
                                    <option *ngFor="let option of listAnalyicalData?.membershipClassSub" [ngValue]="option.id"
                                        class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <!-- Campo Lingua -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="language-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Lingua</label>
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="language" id="language-{{i}}">
                                    <option [ngValue]="null">Non specificato</option>
                                    <option *ngFor="let option of listAnalyicalData?.language" [ngValue]="option.id" class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <!-- Campo Technique -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="technique-{{i}}" class="form-label mt-3" style="font-weight: bold;">Tecnica
                                    di scrittura</label>
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="technique" id="technique-{{i}}">
                                    <option [ngValue]="null">Non specificato</option>
                                    <option *ngFor="let option of listAnalyicalData?.technique" [ngValue]="option.id" class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <!-- Campo charactersType -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="charactersType-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Tipo di caratteri</label>
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="charactersType" id="charactersType-{{i}}">
                                    <option [ngValue]="null">Non specificato</option>
                                    <option *ngFor="let option of listAnalyicalData?.charactersType" [ngValue]="option.id"
                                        class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <!-- Campo Author -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="author-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Autore</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="author-{{i}}" formControlName="author"
                                        [class.is-invalid]="subscriptions.at(i).get('author')?.invalid && (subscriptions.at(i).get('author')?.dirty || submitted)">
                                </div>
                                <div
                                    *ngIf="subscriptions.at(i).get('author')?.invalid && subscriptions.at(i).get('author')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 100 caratteri</span>
                                </div>
                            </div>

                            <!-- Campo location -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="location1-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Posizione</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="location1-{{i}}"
                                        formControlName="location"
                                        [class.is-invalid]="subscriptions.at(i).get('location')?.invalid && (subscriptions.at(i).get('location')?.dirty || submitted)">
                                </div>
                                <div
                                    *ngIf="subscriptions.at(i).get('location')?.invalid && subscriptions.at(i).get('location')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 100 caratteri</span>
                                </div>
                            </div>

                            <!-- Campo transcription -->
                            <div class="col-12">
                                <label for="transcription-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Trascrizione</label>
                                <div class="input-group">
                                    <textarea type="text" class="form-control" id="transcription-{{i}}"
                                        formControlName="transcription"
                                        [class.is-invalid]="subscriptions.at(i).get('transcription')?.invalid && (subscriptions.at(i).get('transcription')?.dirty || submitted)">
                                        </textarea>
                                </div>
                                <div
                                    *ngIf="subscriptions.at(i).get('transcription')?.invalid && subscriptions.at(i).get('transcription')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 10000 caratteri</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Aggiungi nuovo gruppo di campi -->
                    <div class="col-12 mt-1 mb-3 text-center">
                        <button class="btn btn-outline-dark" (click)="addSubscriptions()">
                            <span class="me-2">Aggiungi Iscrizioni</span>
                            <i class="bi bi-plus"></i>
                        </button>
                    </div>

                    <!-- FormArray per Stemmi ed Emblemi -->
                    <div formArrayName="emblems">
                        <div *ngFor="let chronology of emblems.controls; let i = index" [formGroupName]="i"
                            style="border: 1px solid lightgray; border-radius: 10px; position: relative;"
                            class="row justify-content-start pb-2 mt-2">

                            <div *ngIf="i != 0" class="col-12 removeButton">
                                <!-- Rimuovi -->
                                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2"
                                    (click)="removeEmblems(i)">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <label class="d-none d-lg-inline" style="font-weight: bold;">Stemmi, Emblemi, Marchi {{i + 1}}</label><br>
                            </div>
                            <div class="col-12 mt-2 text-center" style="padding-left: 0;">
                                <span class="subTitle">Stemmi, Emblemi, Marchi</span>
                            </div>

                            <!-- Campo Classe di appartenenza -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="membershipClass2-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Classe di appartenenza</label>
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="membershipClass" id="membershipClass2-{{i}}">
                                    <option [ngValue]="null">Non specificato</option>
                                    <option *ngFor="let option of listAnalyicalData?.membershipClassArm" [ngValue]="option.id"
                                        class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <!-- Campo Qualificazione -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="qualification-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Qualificazione</label>
                                <select class="form-select" aria-label="Default select example"
                                    formControlName="qualification" id="qualification-{{i}}">
                                    <option [ngValue]="null">Non specificato</option>
                                    <option *ngFor="let option of listAnalyicalData?.qualification" [ngValue]="option.id"
                                        class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <!-- Campo Identification -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="identification-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Identificazione</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="identification-{{i}}"
                                        formControlName="identification"
                                        [class.is-invalid]="emblems.at(i).get('identification')?.invalid && (emblems.at(i).get('identification')?.dirty || submitted)">
                                </div>
                                <div
                                    *ngIf="emblems.at(i).get('identification')?.invalid && emblems.at(i).get('identification')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 100 caratteri</span>
                                </div>
                            </div>

                            <!-- Campo Quantity -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="quantity-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Quantità</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="quantity-{{i}}"
                                        formControlName="quantity"
                                        [class.is-invalid]="emblems.at(i).get('quantity')?.invalid && (emblems.at(i).get('quantity')?.dirty || submitted)">
                                </div>
                                <div
                                    *ngIf="emblems.at(i).get('quantity')?.invalid && emblems.at(i).get('quantity')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 10 caratteri</span>
                                </div>
                            </div>

                            <!-- Campo Location -->
                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="location2-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Posizione</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="location2-{{i}}"
                                        formControlName="location"
                                        [class.is-invalid]="emblems.at(i).get('location')?.invalid && (emblems.at(i).get('location')?.dirty || submitted)">
                                </div>
                                <div
                                    *ngIf="emblems.at(i).get('location')?.invalid && emblems.at(i).get('location')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 100 caratteri</span>
                                </div>
                            </div>

                            <!-- Campo Description -->
                            <div class="col-12">
                                <label for="description-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Descrizione</label>
                                <div class="input-group">
                                    <textarea type="text" class="form-control" id="description-{{i}}"
                                        formControlName="description"
                                        [class.is-invalid]="emblems.at(i).get('description')?.invalid && (emblems.at(i).get('description')?.dirty || submitted)">
                                        </textarea>
                                </div>
                                <div
                                    *ngIf="emblems.at(i).get('description')?.invalid && emblems.at(i).get('description')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 500 caratteri</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Aggiungi nuovo gruppo di campi -->
                    <div class="col-12 mt-1 text-center">
                        <button class="btn btn-outline-dark" (click)="addEmblems()">
                            <span class="me-2">Aggiungi Stemmi, Emblemi, Marchi</span>
                            <i class="bi bi-plus"></i>
                        </button>
                    </div>

                    <!-- Campo HistoricalNews -->
                    <div class="col-12 pb-2 mt-2">
                        <label for="historycalNews" class="form-label mt-3" style="font-weight: bold;">Notizie
                            Storico-Critiche</label>
                        <div class="input-group">
                            <textarea type="text" class="form-control" id="historycalNews"
                                formControlName="historycalNews"
                                [class.is-invalid]="analyticalForm.get('historycalNews')?.invalid && (analyticalForm.get('historycalNews')?.dirty || submitted)">
                                </textarea>
                        </div>
                        <div
                            *ngIf="analyticalForm.get('historycalNews')?.invalid && analyticalForm.get('historycalNews')?.dirty">
                            <span style="font-size: x-small; color: red;">Max 5000 caratteri</span>
                        </div>
                    </div>
                </form>

                <!-- Pulsante di Salvataggio -->
                <div class="row justify-content-center mt-4 borderTop">
                    <div *ngIf="analyticalForm.invalid && submitted" class="col-12 mt-2 text-center">
                        <small>Compilare correttamente tutti i campi obbligatori per abilitare il
                            salvataggio
                        </small>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
                        <button class="btn btn-outline-dark"
                            (click)="save()">Salva</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
