<div class="mt-2 mb-2">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header class="borderBottom">
                <mat-panel-title><span class="title">Definizione Culturale</span></mat-panel-title>
                <mat-panel-description class="justify-content-end">
                    <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
                    <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="culturalForm">
                    <div formArrayName="author">
                        <div *ngFor="let item of author.controls; let i = index" [formGroupName]="i"
                            style="border: 1px solid lightgray; border-radius: 10px; position: relative"
                            class="row justify-content-center pb-2 mt-2">

                            <div *ngIf="i != 0" class="col-12 removeButton">
                                <!-- Rimuovi -->
                                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2" (click)="removeAuthor(i)"
                                    [disabled]="author.length === 1">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <label class="d-none d-md-inline" style="font-weight: bold;">Autore {{i + 1}}</label><br>
                            </div>
                            <div class="col-12 mt-2 text-center">
                                <span class="subTitle">Autore</span>
                            </div>


                            <div class="col-12 col-md-6">
                                <label for="authorRef-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Riferimento all'autore<sup>*</sup></label>
                                <select class="form-select" id="authorRef-{{i}}" aria-label="Default select example"
                                    formControlName="authorRef"
                                    [class.is-invalid]="item.get('authorRef')?.invalid && (submitted || item.get('authorRef')?.dirty)">
                                    <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                                    <option *ngFor="let option of listCulturalDefinition?.listAuthorAuth" [ngValue]="option.id" class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 col-md-6">
                                <label for="interventionRef1-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Riferimento all'intervento<sup>*</sup></label>
                                <select class="form-select" id="interventionRef1-{{i}}"
                                    aria-label="Default select example" formControlName="interventionRef"
                                    [class.is-invalid]="item.get('interventionRef')?.invalid && (submitted || item.get('interventionRef')?.dirty)">
                                    <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                                    <option *ngFor="let option of listCulturalDefinition?.listAuthorInt" [ngValue]="option.id"
                                        class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <div formArrayName="attributions" class="col-12">
                                <div class="col-12 col-md-4 mt-3">
                                    <label class="form-label mt-2"
                                        style="font-weight: bold; min-width: 300px;">
                                        Motivazione dell'attribuzione<sup>*</sup>
                                    </label>
                                </div>
                                <div class="row">
                                    <div *ngFor="let reason of getAuthorControls(i).controls; let authorIndex = index"
                                        [formGroupName]="authorIndex" class="col-12 col-md-6 col-lg-4 mt-1">
                                        <div class="input-group">
                                            <select class="form-select" id="motivation-{{i}}-{{authorIndex}}"
                                                aria-label="Default select example" formControlName="motivations"
                                                [class.is-invalid]="reason.get('motivations')?.invalid && (submitted || reason.get('motivations')?.dirty)">
                                                <option [ngValue]="null" disabled>Seleziona motivazione cronologica
                                                    {{i+1}}
                                                </option>
                                                <option *ngFor="let option of listCulturalDefinition?.listAuthorReason"
                                                    [ngValue]="option.id" class="form-control">
                                                    {{option.definition}}
                                                </option>
                                            </select>
                                            <button *ngIf="authorIndex != 0" type="button" class="btn btn-danger"
                                                (click)="removeAttributionAuthor(i, authorIndex)"
                                                [disabled]="getAuthorControls(i).length === 1">
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-outline-dark mt-2" type="button"
                                    (click)="addAttributionsAuthor(i)">
                                    <span class="me-2">Aggiungi Motivazione Attribuzione</span>
                                    <i class="bi bi-plus"></i>
                                </button>
                            </div>
                        </div>

                        <!-- Aggiungi nuovo gruppo di campi -->
                        <div class="col-12 mt-1 text-center">
                            <button class="btn btn-outline-dark mb-2" style="max-width: 300px;" (click)="addAuthor()">
                                <span class="me-2">Aggiungi Riferimento all'Autore</span>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div formArrayName="scope">
                        <div *ngFor="let item of scope.controls; let i = index" [formGroupName]="i" class="row"
                            style="border: 1px solid lightgray; border-radius: 10px; position: relative"
                            class="row justify-content-center pb-2 mt-2">
                            <div *ngIf="i != 0" class="col-12 removeButton">
                                <!-- Rimuovi -->
                                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2" (click)="removeScope(i)"
                                    [disabled]="scope.length === 1">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <label class="d-none d-md-inline" style="font-weight: bold;">Denominazione {{i + 1}}</label><br>
                            </div>
                            <div class="col-12 mt-2 mb-3 text-center">
                                <span class="subTitle">Ambito Culturale</span>
                            </div>

                            <div class="col-12 col-md-6">
                                <label for="denomination-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Denominazione<sup>*</sup></label>
                                <select class="form-select" id="denomination-{{i}}" aria-label="Default select example"
                                    formControlName="denomination"
                                    [class.is-invalid]="item.get('denomination')?.invalid && (submitted || item.get('denomination')?.dirty)">
                                    <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                                    <option *ngFor="let option of listCulturalDefinition?.listScopeDen" [ngValue]="option.id"
                                        class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 col-md-6">
                                <label for="interventionRef2-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Riferimento all'intervento</label>
                                <select class="form-select" id="interventionRef2-{{i}}"
                                    aria-label="Default select example" formControlName="interventionRef"
                                    [class.is-invalid]="item.get('interventionRef')?.invalid && (submitted || item.get('interventionRef')?.dirty)">
                                    <option [ngValue]="null">Nessuna Selezione</option>
                                    <option *ngFor="let option of listCulturalDefinition?.listScopeRef" [ngValue]="option.id"
                                        class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <div class="row justify-content-start ps-0 pe-0">
                                <div formArrayName="attributions" class="col-12">
                                    <div class="col-12 col-md-4 mt-3">
                                        <label class="form-label mt-2"
                                            style="font-weight: bold; min-width: 300px;">
                                            Motivazione dell'attribuzione<sup>*</sup>
                                        </label>
                                    </div>
                                    <div class="row">
                                        <div *ngFor="let reason of getScopeControls(i).controls; let scopeIndex = index"
                                            [formGroupName]="scopeIndex" class="col-12 col-md-6 col-lg-4 mt-1">
                                            <div class="input-group">
                                                <select class="form-select" id="motivations-{{i}}-{{scopeIndex}}"
                                                    aria-label="Default select example" formControlName="motivations"
                                                    [class.is-invalid]="reason.get('motivations')?.invalid && (submitted || reason.get('motivations')?.dirty)">
                                                    <option [ngValue]="null">
                                                        Nessuna Selezione
                                                    </option>
                                                    <option *ngFor="let option of listCulturalDefinition?.listScopeReason"
                                                        [ngValue]="option.id" class="form-control">
                                                        {{option.definition}}
                                                    </option>
                                                </select>
                                                <button *ngIf="scopeIndex != 0" type="button" class="btn btn-danger"
                                                    (click)="removeAttributionScope(i, scopeIndex)"
                                                    [disabled]="getScopeControls(i).length === 1">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-dark mt-2" type="button"
                                        (click)="addAttributionsScope(i)">
                                        <span class="me-2">Aggiungi Motivazione Attribuzione</span>
                                        <i class="bi bi-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Aggiungi nuovo gruppo di campi -->
                        <div class="col-12 mt-1 text-center">
                            <button class="btn btn-outline-dark mb-2" style="max-width: 300px;" (click)="addScope()">
                                <span class="me-2">Aggiungi Ambito Culturale</span>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div formArrayName="othersAttributions" class="row pb-2 mt-2">
                        <label class="form-label" style="font-weight: bold;">
                            Altre Attribuzioni
                        </label>
                        <div *ngFor="let item of othersAttributions.controls; let i = index" [formGroupName]="i"
                            class="col-12 col-md-6 col-lg-4 mt-1">
                            <div class="input-group" style="height: 40px;">
                                <input type="text" class="form-control" placeholder="" id="attribution-{{i}}"
                                    formControlName="attributions"
                                    [class.is-invalid]="item.get('attributions')?.invalid && (submitted || item.get('attributions')?.dirty)">
                                <button *ngIf="i != 0" type="button" class="btn btn-danger"
                                    (click)="removeOthersAttributions(i)">
                                    <i class="bi bi-trash"></i>
                                </button>
                            </div>
                            <div
                                *ngIf="item.get('attributions')?.errors?.['maxlength'] && item.get('attributions')?.dirty">
                                <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                            </div>
                        </div>

                        <div class="col-12 mt-2">
                            <button class="btn btn-outline-dark" style="max-width: 300px;"
                                (click)="addOthersAttributions()">
                                Aggiungi Altre Attribuzioni
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>


                    <div formArrayName="client">
                        <div *ngFor="let item of client.controls; let i = index" [formGroupName]="i"
                            style="border: 1px solid lightgray; border-radius: 10px; position: relative;"
                            class="row justify-content-start pb-2 mt-2">
                            <div *ngIf="i != 0" class="col-12 removeButton">
                                <!-- Rimuovi -->
                                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2" (click)="removeClient(i)"
                                    [disabled]="client.length === 1">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <label class="d-none d-md-inline" style="font-weight: bold;">Committenza {{i + 1}}</label><br>
                            </div>
                            <div class="col-12 mt-2 mb-3 text-center">
                                <span class="subTitle">Committenza</span>
                            </div>


                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="date-{{i}}" class="form-label mt-3" style="font-weight: bold;">Data</label>
                                <div class="col-12 col-md-6 input-group">
                                    <input type="text" class="form-control" id="date-{{i}}" formControlName="date"
                                        [class.is-invalid]="item.get('date')?.invalid && (submitted || item.get('date')?.dirty)">
                                </div>
                                <div *ngIf="item.get('date')?.errors?.['maxlength'] && item.get('date')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="circumstance-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Circostanza</label>
                                <div class="col-12 col-md-6 input-group">
                                    <input type="text" class="form-control" id="circumstance-{{i}}"
                                        formControlName="circumstance"
                                        [class.is-invalid]="item.get('circumstance')?.invalid && (submitted || item.get('circumstance')?.dirty)">
                                </div>
                                <div
                                    *ngIf="item.get('circumstance')?.errors?.['maxlength'] && item.get('circumstance')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 100 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="source-{{i}}" class="form-label mt-3"
                                    style="font-weight: bold;">Fonte</label>
                                <div class="col-12 col-md-6 input-group">
                                    <input type="text" class="form-control" id="source-{{i}}" formControlName="source"
                                        [class.is-invalid]="item.get('source')?.invalid && item.get('source')?.dirty">
                                </div>
                                <div
                                    *ngIf="item.get('source')?.errors?.['maxlength'] && item.get('source')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                                </div>
                            </div>

                            <div class="row justify-content-center pe-0">
                                <div formArrayName="names">
                                    <div class="col-12 col-md-4 mt-3">
                                        <label class="form-label mt-2"
                                            style="font-weight: bold; min-width: 300px;">
                                            Nome
                                        </label>
                                    </div>
                                    <div class="row mt-1 pe-0">
                                        <div *ngFor="let item of getClientControls(i).controls; let clientIndex = index"
                                            [formGroupName]="clientIndex" class="col-12 col-md-6 col-lg-4">
                                            <div class="input-group">
                                                <input type="text" class="form-control" id="name-{{i}}"
                                                    formControlName="name"
                                                    [class.is-invalid]="item.get('name')?.invalid && (submitted || item.get('name')?.dirty)">
                                                <button *ngIf="clientIndex != 0" type="button" class="btn btn-danger"
                                                    (click)="removeName(i, clientIndex)">
                                                    <i class="bi bi-trash"></i>
                                                </button>
                                            </div>
                                            <div
                                                *ngIf="item.get('name')?.errors?.['maxlength'] && item.get('name')?.dirty">
                                                <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-dark mt-2" type="button" (click)="addName(i)">
                                        <span class="me-2">Aggiungi Nome</span>
                                        <i class="bi bi-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Aggiungi nuovo gruppo di campi -->
                        <div class="col-12 mt-1 text-center">
                            <button class="btn btn-outline-dark" style="max-width: 300px;" (click)="addClient()">
                                <span class="me-2">Aggiungi Committenza</span>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>
                </form>


                <div class="row justify-content-center mt-4 borderTop">
                    <div *ngIf="culturalForm.invalid && submitted" class="col-12 mt-2 text-center">
                        <small>Compilare correttamente tutti i campi obbligatori per abilitare il
                            salvataggio
                        </small>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
                        <button class="btn btn-outline-dark" (click)="save()">
                            Salva
                        </button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
