import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cultural-definition-readonly',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './cultural-definition-readonly.component.html',
  styleUrl: './cultural-definition-readonly.component.scss'
})
export class CulturalDefinitionReadonlyComponent {

  @Input() culturalDefinition: any = null;

}
