<div class="container-fluid">
    <div class="innerTextFormat row justify-content-start">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Oggetto</span>
        </div>
        <div *ngIf="objectInfo?.obj_definitionlevel1?.definition" class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Definizione: </strong> {{objectInfo?.obj_definitionlevel1.definition}}
        </div>
        <div *ngIf="objectInfo?.obj_definitionlevel2?.definition" class="col-12 col-sm-6 col-lg-12  col-xxl-6 mb-1">
            <strong> Tipologia: </strong> {{objectInfo?.obj_definitionlevel2.definition}}
        </div>
        <div *ngIf="objectInfo?.obj_definition_level3?.definition" class="col-12 col-sm-6 col-lg-12  col-xxl-6 mb-1">
            <strong> Identificazione: </strong> {{objectInfo?.obj_definition_level3.definition}}
        </div>
        <div class="col-12 mb-1">
            <strong> Quantità: </strong> {{objectInfo?.quantity}}
        </div>
        <div
            class="col-12 text-center mb-1 pt-1" style="border-top: 1px solid lightgray;">
            <span class="subtitle">Soggetto</span>
        </div>
        <div class="col-12 mb-1">
            <strong> Identificazione: </strong>
            <span *ngFor="let item of objectInfo?.obj_identification; let i = index" class="me-1">
                {{item.definition}}<span *ngIf="i+1 < objectInfo.obj_identification.length">;</span>
            </span>
        </div>
        <div class="col-12 mb-1">
            <strong> Titolo: </strong> 
            <span *ngFor="let item of objectInfo?.obj_title; let i = index" class="me-1">
                {{item.definition}}<span *ngIf="i+1 < objectInfo.obj_title.length">;</span>
            </span>
        </div>
    </div>
</div>