<div class="container-fluid" *ngIf="type == 1">
    <div class="row justify-content-center">
        <div *ngFor="let article of articlesH; let i = index" style="z-index: 900; overflow: hidden;"
            class="col-12 col-md-6 col-lg-6 col-xxl-4 col-xxxl-3 p-2 mt-1 d-flex justify-content-center text-center">
            <div class="custom-card" id="custom-card-{{i}}" (click)="cardClick(article.id)" appInViewport>
                <img *ngIf="article.ext == 'pdf'; else imageBlockH" [src]="thumbnailsH[article.src!] || 'assets/img/pdfCover.png'" class="custom-img"
                    oncontextmenu="return false;">
                <ng-template #imageBlockH>
                    <img class="custom-img" [src]="urlServerLaraApiMultimedia + article.src"
                        oncontextmenu="return false;">
                </ng-template>
                <div class="custom-body">
                    <span class="title">
                        {{article.title}}
                    </span>
                </div>
                <div class="custom-info">
                    <span class="category">
                        {{article.category.title}} 
                    </span>
                    <span *ngIf="article.subcategory" class="subcategory">
                        ({{article.subcategory}})
                    </span><br>
                    <span *ngIf="article.city" class="dateCity">
                        {{article.city}}
                    </span><br>
                    <span *ngIf="article.date_type == 1" class="dateCity">
                        {{article.date_article}}
                    </span>
                    <span *ngIf="article.date_type == 2" class="dateCity">
                        {{article.year_article}}
                    </span>
                    <span *ngIf="article.date_type == 3" class="dateCity">
                        {{article.yearfrom_article}} - {{article.yearto_article}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid" *ngIf="type == 2">
    <div class="row justify-content-center">
        <div *ngFor="let article of articlesA; let i = index" style="z-index: 900;"
            class="col-12 col-md-6 col-xl-4 col-xxl-3 p-2 mt-1 d-flex justify-content-center text-center">
            <div class="custom-card" id="custom-card-{{i}}" (click)="cardClick(article.id)" appInViewport>
                <img *ngIf="article.ext == 'pdf'; else imageBlockA"
                    [src]="thumbnailsA[article.src!] || 'assets/img/pdfCover.png'" oncontextmenu="return false;">
                <ng-template #imageBlockA>
                    <img class="custom-img" [src]="urlServerLaraApiMultimedia + article.src"
                        oncontextmenu="return false;">
                </ng-template>
                <div class="custom-body">
                    <span class="title">
                        {{article.title}}
                    </span>
                </div>
                <div class="custom-info">
                    <span class="description">
                        {{article.description}}
                    </span><br>
                    <span *ngIf="article.pointofinterest" class="dateCity">
                        {{article.pointofinterest}}
                    </span><br>
                    <span class="dateCity">
                        {{article.public_year}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>