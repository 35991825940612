import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-object-readonly',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './object-readonly.component.html',
  styleUrl: './object-readonly.component.scss'
})
export class ObjectReadonlyComponent {

  @Input() objectInfo: any = null;

}
