import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectOptions } from '../../interfaces/select-options';
import { SelectChronology } from '../../interfaces/select-chronology';
import { ConnectServerService } from '../../../../services/connect-server.service';
import { CheckFormValidityService } from '../../../../services/check-form-validity.service';
import { PopupDialogService } from '../../../../services/popup-dialog.service';
import { Connect } from '../../../../classes/connect';
import { ApiResponse } from '../../../../interfaces/api-response';

interface Chronology {
  id: number;
  century: string;
  centuryFraction: number | null;
  from: string;
  validity_from: number | null;
  to: string, validity_to: number | null;
  chronology_reason: { id: number, reason: number | null }[];
  otherDates: { id: number, date: string }[]
}

@Component({
  selector: 'app-chronology',
  standalone: true,
  imports: [
    MatExpansionModule,
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './chronology.component.html',
  styleUrl: './chronology.component.scss',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChronologyComponent implements OnInit {

  readonly panelOpenState = signal(false);

  @Input() idarticle: number = 0;
  @Input() listChronology: SelectChronology | null = null;

  saved: boolean = false;
  submitted: boolean = false;
  chronologyForm: FormGroup;

  constructor(private fb: FormBuilder, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService, private checkFormValidityService: CheckFormValidityService) {
    // Inizializza il form con un chronologyForm di default
    this.chronologyForm = this.fb.group({
      chronologies: this.fb.array([]) // FormArray con un set iniziale
    });
  }

  ngOnInit(): void {
    this.getChronologyInfo();
  }

  // Crea un singolo chronologyForm
  createChronologyForm(): FormGroup {
    return this.fb.group({
      id: [0],
      century: [null, [Validators.required, Validators.maxLength(50)]],
      centuryFraction: [null],
      from: [null, [Validators.required, Validators.maxLength(15)]],
      validity_from: [null],
      to: [null, [Validators.required, Validators.maxLength(15)]],
      validity_to: [null],
      chronologyReason: this.fb.array([this.createChronologyReasonField()]), // FormArray per chronologyReason
      otherDates: this.fb.array([this.createOtherDatesField()]) // FormArray per otherDates
    });
  }

  createChronologyFormValues(content: Chronology): FormGroup {
    return this.fb.group({
      id: [content.id],
      century: [content.century, [Validators.required, Validators.maxLength(50)]],
      centuryFraction: [content.centuryFraction],
      from: [content.from, [Validators.required, Validators.maxLength(15)]],
      validity_from: [content.validity_from],
      to: [content.to, [Validators.required, Validators.maxLength(15)]],
      validity_to: [content.validity_to],
      chronologyReason: this.addChronologyReasonValues(content.chronology_reason), // FormArray per chronologyReason
      otherDates: this.addOtherDatesValues(content.otherDates) // FormArray per otherDates
    });
  }

  // Funzione per ottenere il FormArray 'chronologyReason' per un chronology specifico
  getChronologyReasonControls(chronologyIndex: number): FormArray {
    return this.chronologies.at(chronologyIndex).get('chronologyReason') as FormArray;
  }

  // Funzione per ottenere il FormArray 'otherDates' per un chronology specifico
  getOtherDatesControls(chronologyIndex: number): FormArray {
    return this.chronologies.at(chronologyIndex).get('otherDates') as FormArray;
  }

  // Crea un singolo campo per ChronologyReason
  createChronologyReasonField(): FormGroup {
    return this.fb.group({
      id: [0],
      reason: [null, Validators.required]
    });
  }

  createChronologyReasonFieldValues(chronology_reason: { id: number, reason: number | null }): FormGroup {
    return this.fb.group({
      id: [chronology_reason.id],
      reason: [chronology_reason.reason, Validators.required]
    })
  }

  // Crea un singolo campo per OtherDates
  createOtherDatesField(): FormGroup {
    return this.fb.group({
      id: [0],
      date: [null, Validators.maxLength(250)]
    });
  }

  createOtherDatesFieldValues(otherdates: { id: number, date: string }): FormGroup {
    return this.fb.group({
      id: [otherdates.id],
      date: [otherdates.date, Validators.maxLength(250)]
    })
  }

  // Getter per il FormArray dei chronologies
  get chronologies(): FormArray {
    return this.chronologyForm.get('chronologies') as FormArray;
  }

  // Aggiungi un nuovo chronologyForm
  addChronology() {
    this.chronologies.push(this.createChronologyForm());
  }

  addChronologyValues(content: Chronology) {
    this.chronologies.push(this.createChronologyFormValues(content));
  }

  // Rimuovi un chronologyForm, ma lascia almeno uno
  removeChronology(index: number) {
    if (this.chronologies.length > 1) {
      this.chronologies.removeAt(index);
    }
  }

  // Aggiungi un campo ChronologyReason in un singolo chronologyForm
  addChronologyReason(chronologyIndex: number) {
    const reasonsArray = this.chronologies.at(chronologyIndex).get('chronologyReason') as FormArray;
    reasonsArray.push(this.createChronologyReasonField());
  }

  addChronologyReasonValues(chronology_reason: { id: number, reason: number | null }[]): FormArray {
    const reasonsArray = this.fb.array([]) as FormArray;
    chronology_reason.forEach(item => {
      reasonsArray.push(this.createChronologyReasonFieldValues(item));
    });
    return reasonsArray;
  }

  // Rimuovi un campo ChronologyReason
  removeChronologyReason(chronologyIndex: number, reasonIndex: number) {
    const reasonsArray = this.chronologies.at(chronologyIndex).get('chronologyReason') as FormArray;
    if (reasonsArray.length > 1) {
      reasonsArray.removeAt(reasonIndex);
    }
  }

  // Aggiungi un campo OtherDates in un singolo chronologyForm
  addOtherDates(chronologyIndex: number) {
    const otherDatesArray = this.chronologies.at(chronologyIndex).get('otherDates') as FormArray;
    otherDatesArray.push(this.createOtherDatesField());
  }

  addOtherDatesValues(otherDates: { id: number, date: string }[]): FormArray {
    const reasonsArray = this.fb.array([]) as FormArray;
    otherDates.forEach(item => {
      reasonsArray.push(this.createOtherDatesFieldValues(item));
    });
    return reasonsArray;
  }

  // Rimuovi un campo OtherDates
  removeOtherDates(chronologyIndex: number, dateIndex: number) {
    const otherDatesArray = this.chronologies.at(chronologyIndex).get('otherDates') as FormArray;
    if (otherDatesArray.length > 1) {
      otherDatesArray.removeAt(dateIndex);
    }
  }

  private getChronologyInfo() {
    this.connectServerService.getRequest('chronologyInfo', { idarticle: this.idarticle })
      .subscribe(
        (val: ApiResponse<{ chronologyForm: any }>) => {
          //this.chronologyForm.reset();
          this.chronologies.clear();
          //this.addChronology();
          this.createNestedFields(val.data.chronologyForm);
          this.saved = this.checkFormValidityService.areAllRequiredFieldsFilled(this.chronologyForm);
          if(this.saved) {
            this.panelOpenState.set(false);
          }
          else {
            this.panelOpenState.set(true);
          }
        }
      )
  }

  private createNestedFields(chronologyForm: Chronology[]) {
    if (chronologyForm.length == 0) {
      this.addChronology();
    }
    else {
      chronologyForm.forEach(element => {
        this.addChronologyValues(element);
      });
    }
  }

  private setChronologyInfo() {
    const formValues = this.chronologyForm.getRawValue();
    // console.log(formValues);
    this.connectServerService.postRequest('updateChronologyInfo',
      { idarticle: this.idarticle, infoChronology: formValues })
      .subscribe(
        (val: ApiResponse<null>) => {
          this.popupDialogService.popupDialog(val);
          this.getChronologyInfo();
        }
      )
  }

  save() {
    this.submitted = true;
    if(this.chronologyForm.valid) {
      this.setChronologyInfo();
    }

  }

}
