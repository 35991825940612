import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sources-readonly',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './sources-readonly.component.html',
  styleUrl: './sources-readonly.component.scss'
})
export class SourcesReadonlyComponent {

  @Input() sources: any = null;

}
