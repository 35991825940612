import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { NgxSpinnerModule } from 'ngx-spinner';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, NavbarComponent, FooterComponent, NgxSpinnerModule]
})
export class AppComponent {
  title = 'Archivio Misericordia Borgo San Lorenzo';
  constructor(private titleService: Title, private metaService: Meta) {
    this.setStaticSEO();
  }

  setStaticSEO() {
    // Aggiorna titolo della pagina
    this.titleService.setTitle('Archivio Misericordia Borgo San Lorenzo');

    // Aggiorna meta tag
    this.metaService.addTags([
      { name: 'description', content: 'Scopri la storia e i documenti dell\'Archivio della Misericordia di Borgo San Lorenzo.' },
      { name: 'keywords', content: 'archivio misericordia borgo san lorenzo, documenti storici, archivio storico' },
      { property: 'og:title', content: 'Archivio Storico Misericordia Borgo San Lorenzo' },
      { property: 'og:description', content: 'Documenti storici e memoria della comunità.' },
      { property: 'og:image', content: 'https://archiviomisericordia.it/assets/img/archivio-misericordia-borgo-san-lorenzo.jpg' },
      { property: 'og:url', content: 'https://archiviomisericordia.it' },
      { property: 'og:type', content: 'website' }
    ]);
  }
}
