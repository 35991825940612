<div class="container-fluid">
    <div class="innerTextFormat row justify-content-center">
        <div class="col-12 mb-1">
            <strong> Ubicazione Originaria: </strong> {{locationData?.original_location}}
        </div>
        <div *ngFor="let item of locationData?.museumInventory; let i = index"
            class="row innerTextFormat justify-content-center">
            <div class="col-12 text-center mb-1 pt-1" style="border-top: 1px solid lightgray;">
                <span class="subtitle">inventario di Museo o Soprintendenza {{i+1}}</span>
            </div>
            <div class="col-12 col-sm-6 col-lg-12 col-xl-6 col-xxl-3 mb-1">
                <strong> Numero: </strong> {{item.number}}
            </div>
            <div class="col-12 col-sm-6 col-lg-12 col-xl-6 col-xxl-3 mb-1">
                <strong> Data: </strong> {{item.date}}
            </div>
            <div class="col-12 col-xxl-6 mb-1">
                <strong> Collocazione: </strong> {{item.location}}
            </div>
        </div>
    </div>
</div>