import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-legal-condition-readonly',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './legal-condition-readonly.component.html',
  styleUrl: './legal-condition-readonly.component.scss'
})
export class LegalConditionReadonlyComponent {

  @Input() legalCondition: any = null;

}
