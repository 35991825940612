<div class="container-fluid">
    <div class="row innerTextFormat justify-content-start">
        <div class="col-12 text-center subtitle">
            Tecniche
        </div>
        <div class="col-12 mb-1">
            <strong> Materia e Tecniche: </strong>
            <span *ngFor="let item of technicalData?.techniques; let i = index" class="me-1">
                {{item.definition}}<span *ngIf="i+1 < technicalData?.techniques.length">,</span> 
            </span>
        </div>
    </div>

    <div *ngFor="let item of technicalData?.measures; let i = index" class="row innerTextFormat justify-content-start"
        style="border-top: 1px solid lightgray;">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Misure {{i+1}}</span>
        </div>
        <div *ngIf="item?.unit?.definition" class="col-12 mb-1">
            <strong> Unità di Misura: </strong>
            {{item.unit.definition}}
        </div>
        <div *ngIf="item?.height" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-1">
            <strong> Altezza: </strong> {{item.height}}
        </div>
        <div *ngIf="item?.width" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-1">
            <strong> Larghezza: </strong> {{item.width}}
        </div>
        <div *ngIf="item?.depth" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-1">
            <strong> Profondità: </strong> {{item.depth}}
        </div>
        <div *ngIf="item?.diameter" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-1">
            <strong> Diametro: </strong> {{item.diameter}}
        </div>
        <div *ngIf="item?.thickness" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-1">
            <strong> Spessore: </strong> {{item.thickness}}
        </div>
        <div *ngIf="item?.weight" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-1">
            <strong> Peso: </strong> {{item.weight}}
        </div>
        <div *ngIf="item?.missing" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-1">
            <strong> Mancanza: </strong> {{item.missing}}
        </div>
        <div *ngIf="item?.validity" class="col-12 col-md-6 col-xl-4 col-xxl-3 mb-1">
            <strong> Validità: </strong> {{item.validity}}
        </div>
        <div class="col-12 mb-1">
            <strong> Varie: </strong>
            <span *ngFor="let el of item.others" class="me-1">
                {{el.other}}<span *ngIf="i+1 < item.others.length">;</span>
            </span>
        </div>
    </div>
</div>