import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-technical-data-readonly',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './technical-data-readonly.component.html',
  styleUrl: './technical-data-readonly.component.scss'
})
export class TechnicalDataReadonlyComponent {

  @Input() technicalData: any = null;

}
