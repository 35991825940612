<div class="container-fluid">
    <div class="innerTextFormat row justify-content-start">
        <div class="col-12 mb-1" style="font-style: italic; color: gray;">
            Codice Articolo: {{generalInfo?.code}}
        </div>
        <div class="col-12 mb-1">
            <strong> Titolo: </strong> {{generalInfo?.title}}
        </div>
        <div *ngIf="generalInfo?.storage_place !== null"
            class="col-12 mb-1">
            <strong> Luogo di conservazione: </strong> {{generalInfo?.storage_place}}
        </div>
        <div *ngIf="generalInfo?.floor != null" class="col-6 mb-1">
            <strong> Piano: </strong> {{generalInfo?.floor}}
        </div>
        <div *ngIf="generalInfo?.room != null" class="col-6 mb-1">
            <strong> Stanza: </strong> {{generalInfo?.room}}
        </div>
        <div *ngIf="generalInfo?.rack != null" class="col-6 mb-1">
            <strong> Scaffale: </strong> {{generalInfo?.rack}}
        </div>
        <div *ngIf="generalInfo?.shelf != null" class="col-6 mb-1">
            <strong> Ripiano: </strong> {{generalInfo?.shelf}}
        </div>
        <div *ngIf="generalInfo?.case != null" class="col-6 mb-1">
            <strong> Contenitore: </strong> {{generalInfo?.case}}
        </div>
    </div>
</div>