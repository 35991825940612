<div class="container-fluid" style="min-height: 86%; min-width: 350px; background-color: #F2F2F2; margin-top: 66px;">

    <div class="row justify-content-center align-items-center pt-3">
        <div class="col-12 col-md-1 text-start mb-1" style="z-index: 1001;">
            <button class="btn btn-sm btn-outline-dark" (click)="goBack()">
                <i class="bi bi-arrow-left"></i>
            </button>
        </div>

        <div class="col-12 col-md-10 text-center mb-1">
            <span style="font-size: xx-large; font-weight: bold;">{{article?.generalForm?.title}}</span>
        </div>

        <div class="d-none d-md-block col-1">

        </div>
    </div>

    <div class="row ps-md-5 pe-md-5 p-0 p-sm-1 p-md-3 p-lg-3 p-xl-4 justify-content-center pagePadding rowWidth">
        <div class="col-12 order-1 order-md-2 col-lg-5">
            <div class="col-12 pb-lg-2 pt-2 pt-lg-0">
                <div class="card" style="height: 100%;">
                    <div class="card-header text-center" style="background-color: #1E73BE;">
                        <span style="font-weight: bold; color: white; font-size: large;">Informazioni Generali</span>
                    </div>
                    <div class="card-body">
                        <app-general-info-readonly [generalInfo]="article?.generalForm"></app-general-info-readonly>
                    </div>
                </div>
            </div>

            <div class="col-12 pb-lg-2 pt-2 pt-lg-0">
                <div class="card" style="height: 100%;">
                    <div class="card-header text-center" style="background-color: #1E73BE;">
                        <span style="font-weight: bold; color: white; font-size: large;">Informazioni Oggetto e
                            Soggetto</span>
                    </div>
                    <div class="card-body">
                        <app-object-readonly [objectInfo]="article?.objectForm"></app-object-readonly>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="article?.description" class="d-none d-lg-block col-12 pb-lg-2 pt-2 pt-lg-0">
                <div class="card" style="height: 100%;">
                    <div style="background-color: #1E73BE;" class="card-header text-center">
                        <span style="font-weight: bold; color: white; font-size: large;">Descrizione</span>
                    </div>
                    <div class="card-body">
                        <div class="innerTextFormat">
                            <p class="text-center" style="font-size: medium; font-style: italic;">
                                {{article?.description}}
                            </p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="col-12 col-lg-7 ms-4 me-4 ms-lg-0 me-lg-0 pt-2 pt-lg-0 order-2 order-lg-1 text-center">
            <div id="carouselBody">
                <div id="custCarousel" class="carousel slide" data-ride="carousel">
                    <!-- slides -->
                    <div *ngFor="let img of files; let i = index" class="carousel-inner">
                        <div class="carousel-item" [class.active]="i === currentImg">
                            <img *ngIf="img.ext != 'pdf' && img.ext != 'mp4' && img.ext != 'youtube'"
                                class="coverImg mt-4" [src]="urlServerLaraApiMultimedia + img.src"
                                matTooltip="Visualizza immagine a schermo intero" [title]="img.title"
                                (click)="openFullscreenModal(img)" oncontextmenu="return false;">
                            <img *ngIf="img.ext == 'pdf'" class="coverImg mt-4"
                                [src]="thumbnails[img.src] || 'assets/img/pdfCover.png'"
                                matTooltip="Visualizza immagine a schermo intero" [title]="img.title"
                                (click)="openFullscreenModal(img)">
                            <video *ngIf="img.ext == 'mp4'" class="coverImg mt-4" controls>
                                <source [src]="urlServerLaraApiMultimedia + img.src" type="video/mp4">
                            </video>
                            <iframe *ngIf="img.ext == 'youtube'" [src]="img.sanitized_url" frameborder="0"
                                class="coverVideo mt-4"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>

                    <a class="carousel-control-prev arrow" data-bs-target="#custCarousel" (click)="prevImg()">
                        <span class="carousel-control-prev-icon" style="filter: invert(1);"></span>
                    </a>
                    <a class="carousel-control-next arrow" data-bs-target="#custCarousel" (click)="nextImg()">
                        <span class="carousel-control-next-icon" style="filter: invert(1);"></span>
                    </a>

                    <!-- Container thumbnails -->
                    <div class="thumbnail-slider-wrapper">
                        <!-- Thumbnails -->
                        <ol class="carousel-indicators list-inline thumbList" id="thumbListScroll">
                            <li class="list-inline-item" *ngFor="let img of this.files; let i = index;"
                                [class.active]="i === currentImg">
                                <a id="carousel-selector-{{i}}" class="selected" data-target="#custCarousel">
                                    <img *ngIf="img.ext != 'pdf' && img.ext != 'mp4' && img.ext != 'youtube'"
                                        class="img-fluid modalImg" oncontextmenu="return false;"
                                        [src]="urlServerLaraApiMultimedia + img.src" [title]="img.title"
                                        (click)="goToImg(i)">
                                    <img *ngIf="img.ext == 'pdf'"
                                        [src]="thumbnails[img.src] || 'assets/img/pdfCover.png'"
                                        class="img-fluid modalImgPdf" [title]="img.title" (click)="goToImg(i)">
                                    <img *ngIf="img.ext == 'mp4' || img.ext == 'youtube'" class="img-fluid modalImg"
                                        [src]="urlServerLaraApiMultimedia + img.src_placeholder" (click)="goToImg(i)">
                                </a>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row ps-md-5 pe-md-5 p-0 ps-lg-3 pe-lg-3 ps-xl-4 pe-xl-4 justify-content-center pagePadding rowWidth">
        <!-- UBICAZIONE E DATI PATRIMONIALI -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Ubicazione e Dati
                        Patrimoniali</span>
                </div>
                <div class="card-body">
                    <app-location-data-readonly [locationData]="article?.locationForm"></app-location-data-readonly>
                </div>
            </div>
        </div>

        <!-- CRONOLOGIA -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Cronologia</span>
                </div>
                <div class="card-body">
                    <app-chronology-readonly [chronologyData]="article?.chronologyForm"></app-chronology-readonly>
                </div>
            </div>
        </div>

        <!-- DEFINIZIONE CULTURALE -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Definizione Culturale</span>
                </div>
                <div class="card-body">
                    <app-cultural-definition-readonly [culturalDefinition]="article?.culturalForm"></app-cultural-definition-readonly>
                </div>
            </div>
        </div>

        <!-- DATI ANALITICI -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Dati Analitici</span>
                </div>
                <div class="card-body">
                    <app-analytical-data-readonly [analyticalData]="article?.analyticalDataForm"></app-analytical-data-readonly>
                </div>
            </div>
        </div>



        <!-- DATI TECNICI -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Dati Tecnici</span>
                </div>
                <div class="card-body">
                    <app-technical-data-readonly [technicalData]="article?.technicalDataForm"></app-technical-data-readonly>
                </div>
            </div>
        </div>

        <!-- CONDIZIONE GIURIDICA E VINCOLI -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Condizione Giuridica e Vincoli</span>
                </div>
                <div class="card-body">
                    <app-legal-condition-readonly [legalCondition]="article?.legalConditionForm"></app-legal-condition-readonly>
                </div>
            </div>
        </div>

        <!-- FONTI E DOCUMENTI DI RIFERIMENTO -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card" style="height: 100%;">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Fonti e Documenti di Riferimento</span>
                </div>
                <div class="card-body">
                    <app-sources-readonly [sources]="article?.sourcesForm"></app-sources-readonly>
                </div>
            </div>
        </div>

        <!-- CONSERVAZIONE -->
        <div class="col-12 col-lg-6 pb-lg-2 pt-2 pt-lg-0">
            <div class="card">
                <div class="card-header text-center" style="background-color: #1E73BE;">
                    <span style="font-weight: bold; color: white; font-size: large;">Conservazione</span>
                </div>
                <div class="card-body">
                    <app-conservation-readonly [conservationData]="article?.conservationForm"></app-conservation-readonly>
                </div>
            </div>
        </div>
    </div>

    <!-- MODIFICA SCHEDA ED ELIMINA -->
    <div *ngIf="authService.isLoggedIn(); else notLoggedBlock" class="row text-center justify-content-center mt-4 pb-4">
        <div class="col-12 col-sm-6 col-md-4 col-xl-2">
            <button class="btn btn-warning mt-2" [routerLink]="['/modificaSchedaArtistica', article?.generalForm?.id]"
                style="width: 170px;">
                <i class="bi bi-pencil"></i>
                Modifica Scheda
            </button>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-xl-2">
            <button class="btn btn-danger mt-2" (click)="deleteArticle(article?.generalForm?.id!)"
                style="width: 170px;">
                <i class="bi bi-trash"></i>
                Elimina
            </button>
        </div>
    </div>

    <ng-template #notLoggedBlock>
        <div *ngIf="false" class="row text-center justify-content-center mt-4 pb-4">
            <div class="col-12 col-md-6 col-xl-4">
                <button class="btn btn-sm btn-outline-success mt-2" [routerLink]="['/richiediAccesso']">
                    <i class="bi bi-person"></i>
                    Richiedi Visualizzazione Completa
                </button>
            </div>
        </div>
    </ng-template>
</div>