<div class="mt-2 mb-2">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)"
            (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header class="borderBottom">
                <mat-panel-title><span class="title">Dati Tecnici</span></mat-panel-title>
                <mat-panel-description class="justify-content-end">
                    <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
                    <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="technicalDataForm">

                    <div class="row justify-content-start">
                        <div formArrayName="techniques">
                            <div class="col-12 mt-2 text-center">
                                <span class="subTitle">Tecniche</span>
                            </div>
                            <div class="row pb-2 mt-2">
                                <div class="col-12">
                                    <label class="form-label mt-3" style="font-weight: bold;">
                                        Materia e Tecnica
                                    </label>
                                </div>
                                <div *ngFor="let inventory of techniques.controls; let i = index" [formGroupName]="i"
                                    class="col-12 col-md-6 col-lg-4 mt-1">
                                    <div class="input-group">
                                        <select class="form-select" id="technique-{{i}}"
                                            aria-label="Default select example" formControlName="technique"
                                            [class.is-invalid]="inventory.get('technique')?.invalid && (inventory.get('technique')?.dirty || submitted)">
                                            <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                                            <option *ngFor="let option of listTechnicalData?.materialOptions"
                                                [ngValue]="option.id" class="form-control">
                                                {{option.definition}}
                                            </option>
                                        </select>
                                        <button *ngIf="i != 0" type="button" class="btn btn-danger"
                                            (click)="removeTechniques(i)">
                                            <i class="bi bi-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pb-2">
                                <button class="btn btn-outline-dark mb-2" style="max-width: 300px;"
                                    (click)="addTechnique()">
                                    Aggiungi Altre Tecniche
                                    <i class="bi bi-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- Misure -->
                    <div formArrayName="measures">
                        <div *ngFor="let inventory of measures.controls; let i = index" [formGroupName]="i"
                            style="border: 1px solid lightgray; border-radius: 10px; position: relative;"
                            class="row justify-content-start pb-2 mt-2">
                            <div *ngIf="i != 0" class="col-12 removeButton">
                                <!-- Rimuovi -->
                                <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2"
                                    (click)="removeMeasures(i)">
                                    <i class="bi bi-trash"></i>
                                </button>
                                <label class="d-none d-md-inline" style="font-weight: bold;">Misure {{i +
                                    1}}</label><br>
                            </div>
                            <div class="col-12 mt-2 text-center">
                                <span class="subTitle">Misure</span>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="unit-{{i}}" class="form-label mt-3" style="font-weight: bold;">Unità di
                                    misura<sup>*</sup></label>
                                <select class="form-select" id="unit-{{i}}" aria-label="Default select example"
                                    formControlName="unit"
                                    [class.is-invalid]="inventory.get('unit')?.invalid && (inventory.get('unit')?.dirty || submitted)">
                                    <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                                    <option *ngFor="let option of listTechnicalData?.unitOptions" [ngValue]="option.id"
                                        class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="height-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Altezza
                                </label>
                                <input type="text" class="form-control" id="height-{{i}}" formControlName="height"
                                    [class.is-invalid]="inventory.get('height')?.invalid && (inventory.get('height')?.dirty || submitted)">
                                <div *ngIf="inventory.get('height')?.invalid && inventory.get('height')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 6 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="width-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Larghezza
                                </label>
                                <input type="text" class="form-control" id="width-{{i}}" formControlName="width"
                                    [class.is-invalid]="inventory.get('width')?.invalid && (inventory.get('width')?.dirty || submitted)">
                                <div *ngIf="inventory.get('width')?.invalid && inventory.get('width')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 6 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="depth-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Profondità
                                </label>
                                <input type="text" class="form-control" id="depth-{{i}}" formControlName="depth"
                                    [class.is-invalid]="inventory.get('depth')?.invalid && (inventory.get('depth')?.dirty || submitted)">
                                <div *ngIf="inventory.get('depth')?.invalid && inventory.get('depth')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 6 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="diameter-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Diametro
                                </label>
                                <input type="text" class="form-control" id="diameter-{{i}}" formControlName="diameter"
                                    [class.is-invalid]="inventory.get('diameter')?.invalid && (inventory.get('diameter')?.dirty || submitted)">
                                <div *ngIf="inventory.get('diameter')?.invalid && inventory.get('diameter')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 6 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="length-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Lunghezza
                                </label>
                                <input type="text" class="form-control" id="length-{{i}}" formControlName="length"
                                    [class.is-invalid]="inventory.get('length')?.invalid && (inventory.get('length')?.dirty || submitted)">
                                <div *ngIf="inventory.get('length')?.invalid && inventory.get('length')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 6 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="thickness-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Spessore
                                </label>
                                <input type="text" class="form-control" id="thickness-{{i}}" formControlName="thickness"
                                    [class.is-invalid]="inventory.get('thickness')?.invalid && (inventory.get('thickness')?.dirty || submitted)">
                                <div *ngIf="inventory.get('thickness')?.invalid && inventory.get('thickness')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 6 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="weight-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Peso
                                </label>
                                <input type="text" class="form-control" id="weight-{{i}}" formControlName="weight"
                                    [class.is-invalid]="inventory.get('weight')?.invalid && (inventory.get('weight')?.dirty || submitted)">
                                <div *ngIf="inventory.get('weight')?.invalid && inventory.get('weight')?.dirty">
                                    <span style="font-size: x-small; color: red;">Max 6 caratteri</span>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="missing-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Mancanza
                                </label>
                                <select class="form-select" id="missing-{{i}}" aria-label="Default select example"
                                    formControlName="missing"
                                    [class.is-invalid]="inventory.get('missing')?.invalid && (inventory.get('missing')?.dirty || submitted)">
                                    <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                                    <option *ngFor="let option of listTechnicalData?.missingOptions"
                                        [ngValue]="option.id" class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4">
                                <label for="validity-{{i}}" class="form-label mt-3" style="font-weight: bold;">
                                    Validità
                                </label>
                                <select class="form-select" id="validity-{{i}}" aria-label="Default select example"
                                    formControlName="validity"
                                    [class.is-invalid]="inventory.get('validity')?.invalid && (inventory.get('validity')?.dirty || submitted)">
                                    <option [ngValue]="null" disabled>Seleziona riferimento {{i+1}}</option>
                                    <option *ngFor="let option of listTechnicalData?.validityOptions"
                                        [ngValue]="option.id" class="form-control">
                                        {{option.definition}}
                                    </option>
                                </select>
                            </div>

                            <div formArrayName="others" class="col-12">
                                <div class="col-12 col-md-4 mt-3">
                                    <label class="form-label"
                                        style="font-weight: bold; min-width: 300px;">
                                        Varie<sup>*</sup>
                                    </label>
                                </div>
                                <div class="row">
                                    <div *ngFor="let reason of getMeasuresControl(i).controls; let authorIndex = index"
                                        [formGroupName]="authorIndex" class="col-12 col-md-6 col-lg-4 mt-1">
                                        <div class="input-group">
                                            <input type="text" class="form-control" id="other-{{i}}"
                                                formControlName="other"
                                                [class.is-invalid]="reason.get('other')?.invalid && (reason.get('other')?.dirty || submitted)">
                                            <button *ngIf="authorIndex != 0" type="button" class="btn btn-danger"
                                                (click)="removeOthers(i, authorIndex)"
                                                [disabled]="getMeasuresControl(i).length === 1">
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </div>
                                        <div *ngIf="reason.get('other')?.invalid && reason.get('other')?.dirty">
                                            <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-outline-dark mt-2" type="button" (click)="addOthers(i)">
                                    <span class="me-2">Aggiungi Varie</span>
                                    <i class="bi bi-plus"></i>
                                </button>
                            </div>
                        </div>

                        <!-- Aggiungi nuovo gruppo di campi -->
                        <div class="col-12 mt-1 text-center">
                            <button class="btn btn-outline-dark" style="max-width: 300px;" (click)="addMeasures()">
                                <span class="me-2">Aggiungi Misure</span>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>
                </form>
                <div class="row justify-content-center mt-4 borderTop">
                    <div *ngIf="technicalDataForm.invalid && submitted" class="col-12 mt-2 text-center">
                        <small>Compilare correttamente tutti i campi obbligatori per abilitare il
                            salvataggio
                        </small>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
                        <button class="btn btn-outline-dark" (click)="save()">Salva</button>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>