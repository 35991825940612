<div class="container-fluid p-1 p-md-2 p-lg-3" style="min-height: 82%; min-width: 350px; margin-top: 66px;">
  <div class="row p-2 justify-content-center align-items-center text-center"
    style="margin-left: 0 !important; margin-right: 0 !important;">
    <div class="col-12 mt-2" style="position: relative;">
      <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto" style="width: 90%; height: auto;">
      <span class="d-none d-lg-block"
        style="font-weight: bold; color: white; font-size: x-large; position: absolute; top: 63%; left: 38%;">
        GESTIONE AUTORIZZAZIONI FUNZIONI UTENTI
      </span>
    </div>
    <div class="col-8 text-center">
      <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
        <span style="font-weight: bold; font-size: x-large; color: white;">
          GESTIONE AUTORIZZAZIONI FUNZIONI UTENTI
        </span>
      </div>
    </div>

    <div class="col-12 mt-4" style="overflow-x: scroll;">
      <form [formGroup]="permissionsForm">
        <div formArrayName="authorizations">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>User</th>
                <!-- Intestazione dinamica dei componenti, basata su permissionsList -->
                <th *ngFor="let permission of permissionsList">
                  <button mat-button #tooltip="matTooltip" [matTooltip]="permission.description"
                    matTooltipPosition="above"
                    aria-label="Button that displays a tooltip that describe functionalities">
                    {{ permission.name }}
                  </button>

                </th>
              </tr>
            </thead>
            <tbody>
              <!-- Ciclo sui controlli 'authorizations', che contengono gli utenti -->
              <tr *ngFor="let authorization of authorizations.controls; let i = index" [formGroupName]="i">
                <!-- Nome dell'utente -->
                <td>
                  <span [title]="authorization.get('user')?.get('email')?.value">
                    {{ authorization.get('user')?.get('name')?.value }}
                  </span>
                </td>

                <!-- Ciclo sui permessi per ciascun utente -->
                <ng-container formArrayName="permissions">
                  <td *ngFor="let permissionControl of getPermissionsControls(i).controls; let j = index"
                    [formGroupName]="j" align="center">
                      <input class="form-check-input" type="checkbox" formControlName="authorization">
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>

  <div class="row justify-content-center align-items-center p-2">
    <div class="col-12 col-md-6 col-lg-4 text-center">
      <button class="btn btn-outline-dark" (click)="save()">
        Salva
      </button>
    </div>
  </div>
</div>
