<div class="container-fluid">
    <div *ngFor="let item of sources?.picturesSources; let i = index" class="row innerTextFormat justify-content-start">
        <div class="col-12 text-center subtitle">
            Documentazione Fotografica {{i+1}}
        </div>
        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Ente Proprietario: </strong>
            {{item.owner}}
        </div>
        <div class="col-12 col-sm-6 col-lg-12 col-xxl-6 mb-1">
            <strong> Codice Identificativo: </strong>
            {{item.code}}
        </div>
    </div>

    <div *ngFor="let item of sources?.documentSources; let i = index" class="row innerTextFormat justify-content-start"
        style="border-top: 1px solid lightgray;">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Fonti e Documenti {{i+1}}</span>
        </div>
        <div *ngIf="item?.type.definition" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Tipo: </strong>
            {{item.type.definition}}
        </div>
        <div *ngIf="item?.author" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Autore: </strong> {{item.author}}
        </div>
        <div *ngIf="item?.denomination" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Denominazione: </strong> {{item.denomination}}
        </div>
        <div *ngIf="item?.date" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Data: </strong> {{item.date}}
        </div>
        <div *ngIf="item?.paper" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Foglio/Carta: </strong> {{item.paper}}
        </div>
        <div *ngIf="item?.position" class="col-12 mb-1">
            <strong> Posizione: </strong> {{item.position}}
        </div>
        <div *ngIf="item?.archiveName" class="col-12 mb-1">
            <strong> Nome Archivio: </strong> {{item.archiveName}}
        </div>
    </div>

    <div *ngFor="let item of sources?.bibliography; let i = index" class="row innerTextFormat justify-content-start"
        style="border-top: 1px solid lightgray;">
        <div class="col-12 text-center mb-1 pt-1">
            <span class="subtitle">Bibliografia {{i+1}}</span>
        </div>
        <div *ngIf="item?.genre?.definition" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Genre: </strong>
            {{item.genre.definition}}
        </div>
        <div *ngIf="item?.author" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Autore: </strong> {{item.author}}
        </div>
        <div *ngIf="item?.year" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Anno di Edizione: </strong> {{item.year}}
        </div>
        <div *ngIf="item?.acronym" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> Sigla per Citazione: </strong> {{item.acronym}}
        </div>
        <div *ngIf="item?.vPpNn" class="col-12 col-md-6 col-lg-12 col-xl-6 mb-1">
            <strong> V. pp. nn.: </strong> {{item.vPpNn}}
        </div>
        <div *ngIf="item?.vTavvFigg" class="col-12 mb-1">
            <strong> V. Tavv. Figg.: </strong> {{item.vTavvFigg}}
        </div>
    </div>

    <div class="row innerTextFormat" style="border-top: 1px solid lightgray;">
        <div *ngIf="sources?.completeCitations" class="col-12 mb-1 pt-1">
            <strong> Citazione Completa: </strong>
            <span *ngFor="let item of sources.completeCitations; let i = index" class="me-1">
                {{item.completeCitation}}<span *ngIf="i+1 < sources.completeCitations.length">;</span>
            </span>
        </div>
    </div>
</div>