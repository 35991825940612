<div style="min-height: 90vh;">
  <div class="container-fluid p-1 ps-md-4 pe-md-4 pt-md-4 ps-lg-5 pe-lg-5 pt-lg-5"
    style="min-width: 350px; max-width: 1400px; margin-top: 66px;">
    <div class="row justify-content-center align-items-center p-1 p-lg-2">
      <div class="col-12" style="position: relative;">
        <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
          style="width: 90%; height: auto;">
        <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 35%;">
          INSERIMENTO MULTIPLO ARTICOLI
        </span>
      </div>
      <div class="col-8 text-center">
        <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
          <span style="font-weight: bold; font-size: x-large; color: white;">
            INSERIMENTO MULTIPLO ARTICOLI
          </span>
        </div>
      </div>
      <div class="col-12 text-center">
        <span style="font-size: small;">Campi obbligatori <sup>*</sup></span>
      </div>
    </div>

    <form [formGroup]="importForm">

      <div class="row justify-content-center ps-2 pe-2" style="border-bottom: 1px solid lightgrey;">
        <!-- <div class="col-12 col-md-4 p-1">
        <label for="code" class="form-label" style="font-weight: bold;">
          Codice Articolo<sup>* </sup><span style="font-size: x-small; font-weight: lighter;"> progressivo
            calcolato dal server</span>
        </label>
        <div class="input-group mb-2" style="height: 40px;">
          <input type="text" class="form-control" placeholder="Codice Univoco" id="code" formControlName="code">
        </div>
      </div> -->

        <div class="col-12 col-sm-4 p-1">
          <label for="category" class="form-label" style="font-weight: bold;">
            Categoria<sup>*</sup>
          </label>
          <select class="form-select mb-2" aria-label="Default select example" name="category"
            formControlName="category" [class.is-invalid]="importForm.get('category')?.invalid">
            <option [ngValue]="null" disabled selected>Seleziona una categoria</option>
            <option *ngFor="let item of categoryOptions" [ngValue]="item">{{item.title}}</option>
          </select>
        </div>

        <div class="col-12 col-sm-4 p-1">
          <label for="subcategory" class="form-label" style="font-weight: bold;">
            Sottocategoria
            <sup>*</sup>
          </label>
          <select class="form-select mb-2" aria-label="Default select example" formControlName="subcategory"
            [class.is-invalid]="importForm.get('subcategory')?.invalid">
            <option [ngValue]="null">Seleziona una sottocategoria</option>
            <option *ngFor="let option of subcategoryOptions" [ngValue]="option" class="form-control">
              {{option.title}}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-4 p-1">
          <label class="form-label" style="font-weight: bold;">Visibilità Articolo</label><br>
          <div class="form-check form-switch" style="margin-bottom: 1.2rem;">
            <input class="form-check-input form-control" type="checkbox" role="switch" id="public"
              formControlName="public" checked style="cursor: pointer;" (change)="enableDisablePublication()">
            <label class="form-check-label" for="public">Pubblico</label>
          </div>

          <div *ngIf="importForm.get('public')!.value == 1">
            <div class="input-group mb-2" style="height: 38px;">
              <input type="text" class="form-control" placeholder="Anno Pubblicazione" id="public_year"
                formControlName="public_year" (input)="isCorrectPublicYear()" [class.is-invalid]="importForm.get('public_year')?.invalid &&
                            (importForm.get('public_year')?.dirty || importForm.get('public_year')?.touched)">
            </div>
          </div>
        </div>
      </div>

      <div class="row p-2" style="border-bottom: 1px solid lightgrey;">
        <div class="col-12 col-md-4 ps-1 pe-1 pb-1">
          <label for="city" class="form-label" style="font-weight: bold;">Città</label>
          <div class="input-group" style="height: 38px;">
            <input type="text" matInput [matAutocomplete]="city" class="form-control" placeholder="Città" id="city"
              formControlName="city"
              [class.is-invalid]="importForm.get('city')?.invalid && (importForm.get('city')?.dirty || importForm.get('city')?.touched)">
          </div>
        </div>
        <mat-autocomplete #city="matAutocomplete" [displayWith]="displayCityName">
          <mat-option *ngFor="let option of filteredCity$ | async" [value]="option">
            <div>
              {{option.name}}
            </div>
          </mat-option>
        </mat-autocomplete>


        <div class="col-12 col-md-4 ps-1 pe-1 pb-1">
          <label for="pointofinterest" class="form-label" style="font-weight: bold;">
            Luogo d'interesse
          </label>
          <div class="input-group" style="height: 40px;">
            <input type="text" class="form-control" placeholder="Luogo" id="pointofinterest"
              formControlName="pointofinterest">
          </div>
        </div>
        <div class="col-12 col-md-4 ps-1 pe-1 pb-1">
          <label for="storage_place" class="form-label" style="font-weight: bold;">
            Luogo di conservazione<sup>*</sup>
          </label>
          <div class="input-group" style="height: 40px;">
            <input type="text" class="form-control" placeholder="Luogo" id="storage_place"
              formControlName="storage_place" [class.is-invalid]="importForm.get('storage_place')?.invalid">
          </div>
        </div>

      </div>
      <div class="row p-2" style="border-bottom: 1px solid lightgrey;">

        <div class="col-6 col-md-3 ps-1 pe-1 pb-1">
          <label for="floor" class="form-label" style="font-weight: bold;">
            Piano<sup>*</sup>
          </label>
          <div class="input-group" style="height: 40px;">
            <input type="text" class="form-control" placeholder="Piano edificio" id="floor" formControlName="floor"
              [class.is-invalid]="importForm.get('floor')?.invalid">
          </div>
        </div>

        <div class="col-6 col-md-3 ps-1 pe-1 pb-1">
          <label for="room" class="form-label" style="font-weight: bold;">
            Stanza<sup>*</sup>
          </label>
          <div class="input-group" style="height: 40px;">
            <input type="text" class="form-control" placeholder="" id="room" formControlName="room"
              [class.is-invalid]="importForm.get('room')?.invalid">
          </div>
        </div>

        <div class="col-4 col-md-2 ps-1 pe-1 pb-1">
          <label for="rack" class="form-label" style="font-weight: bold;">
            Scaffale<span class="d-none d-lg-inline">/Armadio</span><sup>*</sup>
          </label>
          <div class="input-group" style="height: 40px;">
            <input type="text" class="form-control" placeholder="" id="rack" formControlName="rack"
              [class.is-invalid]="importForm.get('rack')?.invalid">
          </div>
        </div>

        <div class="col-4 col-md-2 ps-1 pe-1 pb-1">
          <label for="shelf" class="form-label" style="font-weight: bold;">
            Ripiano<sup>*</sup>
          </label>
          <div class="input-group" style="height: 40px;">
            <input type="text" class="form-control" placeholder="" id="shelf" formControlName="shelf"
              [class.is-invalid]="importForm.get('shelf')?.invalid">
          </div>
        </div>

        <div class="col-4 col-md-2 ps-1 pe-1 pb-1">
          <label for="case" class="form-label" style="font-weight: bold;">
            Contenitore<sup>*</sup>
          </label>
          <div class="input-group" style="height: 40px;">
            <input type="text" class="form-control" placeholder="" id="case" formControlName="case"
              [class.is-invalid]="importForm.get('case')?.invalid">
          </div>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div *ngIf="!importForm.valid" class="col-12 text-center mt-3 mb-1" style="font-size: small;">Compila
          correttamente tutti i
          campi per abilitare il caricamento</div>
      </div>
    </form>
  </div>

  <div class="container-fluid" style="min-width: 350px; min-height: 200px;">
    <div class="row">
      <div class="col-12 col-xxl-7" style="border-right: 1px solid lightgrey;">
        <app-tree-view #treeView0 (saveArticle_tree)="insertArticle($event)" [archive_type]="0"></app-tree-view>
      </div>
      <div class="col-12 col-xxl-5">
        <app-tree-view #treeView1 [archive_type]="1"></app-tree-view>
      </div>
    </div>
  </div>
</div>