<div class="mt-2 mb-2">
    <mat-accordion>
        <mat-expansion-panel [expanded]="panelOpenState()" (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
            <mat-expansion-panel-header class="borderBottom">
                <mat-panel-title><span class="title">Cronologia</span></mat-panel-title>
                <mat-panel-description class="justify-content-end">
                    <i *ngIf="!saved" class="bi bi-exclamation-circle-fill" style="color: red; font-size: large;"></i>
                    <i *ngIf="saved" class="bi bi-check-circle-fill" style="color: green; font-size: large;"></i>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="chronologyForm">
                    <div formArrayName="chronologies">
                        <div *ngFor="let chronology of chronologies.controls; let i = index" [formGroupName]="i"
                            style="border: 1px solid lightgray; border-radius: 10px; position: relative" class="row justify-content-center pb-2 mt-2">

                                <div *ngIf="i != 0" class="col-12 removeButton">
                                    <!-- Rimuovi -->
                                    <button *ngIf="i != 0" class="btn btn-sm btn-danger me-2 mt-2"
                                        (click)="removeChronology(i)" [disabled]="chronologies.length === 1">
                                        <i class="bi bi-trash"></i>
                                    </button>
                                    <label class="d-none d-md-inline" style="font-weight: bold;">Cronologia {{i + 1}}</label><br>
                                </div>
                                <div class="col-12 mt-2 text-center" style="padding-left: 0;">
                                    <span class="subTitle">Cronologia Generica</span>
                                </div>

                                <!-- Campo Century -->
                                <div class="col-12 col-md-6">
                                    <label for="century-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">Secolo<sup>*</sup></label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="century-{{i}}"
                                            formControlName="century"
                                            [class.is-invalid]="chronologies.at(i).get('century')?.invalid && (submitted || chronologies.at(i).get('century')?.dirty)">
                                    </div>
                                    <div
                                        *ngIf="chronologies.at(i).get('century')?.errors?.['maxlength'] && chronologies.at(i).get('century')?.dirty">
                                        <span style="font-size: x-small; color: red;">Max 50 caratteri</span>
                                    </div>
                                </div>

                                <!-- Campo Century Fraction -->
                                <div class="col-12 col-md-6">
                                    <label for="centuryFraction-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">Frazione di Secolo</label>
                                    <select class="form-select" aria-label="Default select example"
                                        formControlName="centuryFraction" id="centuryFraction-{{i}}">
                                        <option [ngValue]="null">Non specificato</option>
                                        <option *ngFor="let option of listChronology?.listChronCentury" [ngValue]="option.id"
                                            class="form-control">
                                            {{option.definition}}
                                        </option>
                                    </select>
                                </div>

                            <div class="row justify-content-start mt-3 borderTop ps-0 pe-0">
                                <div class="col-12 mt-2 mb-1 text-center">
                                    <span class="subTitle">Cronologia Specifica</span>
                                </div>
                                <!-- Campo From -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <label for="from-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">Da<sup>*</sup></label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="from-{{i}}" formControlName="from"
                                            [class.is-invalid]="chronologies.at(i).get('from')?.invalid && (submitted || chronologies.at(i).get('from')?.dirty)">
                                    </div>
                                    <div
                                        *ngIf="chronologies.at(i).get('from')?.errors?.['maxlength'] && chronologies.at(i).get('from')?.dirty">
                                        <span style="font-size: x-small; color: red;">Max 15 caratteri</span>
                                    </div>
                                </div>

                                <!-- Campo Validity From -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <label for="validity_from-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">Validità (campo "Da")</label>
                                    <select class="form-select mb-2" aria-label="Default select example"
                                        formControlName="validity_from" id="validity_from-{{i}}">
                                        <option [ngValue]="null">Non specificato</option>
                                        <option *ngFor="let option of listChronology?.listChronValV" [ngValue]="option.id"
                                            class="form-control">
                                            {{option.definition}}
                                        </option>
                                    </select>
                                </div>

                                <!-- Campo To -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <label for="to-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">A<sup>*</sup></label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" id="to-{{i}}" formControlName="to"
                                            [class.is-invalid]="chronologies.at(i).get('to')?.invalid && (submitted || chronologies.at(i).get('to')?.dirty)">
                                    </div>
                                    <div
                                        *ngIf="chronologies.at(i).get('to')?.errors?.['maxlength'] && chronologies.at(i).get('to')?.dirty">
                                        <span style="font-size: x-small; color: red;">Max 15 caratteri</span>
                                    </div>
                                </div>

                                <!-- Campo Validity To -->
                                <div class="col-12 col-md-6 col-lg-3">
                                    <label for="validity_to-{{i}}" class="form-label mt-3"
                                        style="font-weight: bold;">Validità (campo "A")</label>
                                    <select class="form-select mb-2" aria-label="Default select example"
                                        formControlName="validity_to" id="validity_to-{{i}}">
                                        <option [ngValue]="null">Non specificato</option>
                                        <option *ngFor="let option of listChronology?.listChronValF" [ngValue]="option.id"
                                            class="form-control">
                                            {{option.definition}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <!-- FormArray per Chronology Reason e Other Dates -->
                            <div class="row justify-content-start ps-0 pe-0">
                                <div formArrayName="chronologyReason" class="col-12 col-md-6 mt-3">
                                    <div class="col-12 col-md-4">
                                        <label class="form-label"
                                            style="font-weight: bold; min-width: 300px;">
                                            Motivazione Cronologica<sup>*</sup>
                                        </label>
                                    </div>
                                    <div *ngFor="let reason of getChronologyReasonControls(i).controls; let reasonIndex = index"
                                        [formGroupName]="reasonIndex" class="row mt-1">

                                        <div class="col-12 col-md-6 input-group">
                                            <select class="form-select" id="reason-{{i}}-{{reasonIndex}}"
                                                aria-label="Default select example" formControlName="reason"
                                                [class.is-invalid]="reason?.invalid && (submitted || reason.dirty)">
                                                <option [ngValue]="null" disabled>Seleziona motivazione cronologica {{i+1}}
                                                </option>
                                                <option *ngFor="let option of listChronology?.listChronReason" [ngValue]="option.id"
                                                    class="form-control">
                                                    {{option.definition}}
                                                </option>
                                            </select>
                                            <button *ngIf="reasonIndex != 0" type="button" class="btn btn-danger"
                                                (click)="removeChronologyReason(i, reasonIndex)"
                                                [disabled]="getChronologyReasonControls(i).length === 1">
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-dark mt-2" type="button"
                                        (click)="addChronologyReason(i)">
                                        <span class="me-2">Aggiungi Motivazione Cronologica</span>
                                        <i class="bi bi-plus"></i>
                                    </button>
                                </div>

                                <div formArrayName="otherDates" class="col-12 col-md-6 mt-3">
                                    <div class="col-12 col-md-4">
                                        <label class="form-label"
                                            style="font-weight: bold;">Altre Datazioni</label>
                                    </div>
                                    <div *ngFor="let reason of getOtherDatesControls(i).controls; let datesIndex = index"
                                        [formGroupName]="datesIndex" class="row mt-1">

                                        <div class="col-12 col-md-6 input-group">
                                            <input type="text" class="form-control" id="date-{{i}}-{{datesIndex}}"
                                                formControlName="date"
                                                [class.is-invalid]="reason.invalid && (submitted || reason.dirty)">
                                            <button *ngIf="datesIndex != 0" type="button" class="btn btn-danger"
                                                (click)="removeOtherDates(i, datesIndex)"
                                                [disabled]="getOtherDatesControls(i).length === 1">
                                                <i class="bi bi-trash"></i>
                                            </button>
                                        </div>
                                        <div
                                            *ngIf="reason.errors?.['maxlength'] && reason.dirty">
                                            <span style="font-size: x-small; color: red;">Max 250 caratteri</span>
                                        </div>
                                    </div>
                                    <button class="btn btn-outline-dark mt-2" type="button" (click)="addOtherDates(i)">
                                       <span class="me-2">Aggiungi Altre Datazioni</span>
                                       <i class="bi bi-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Aggiungi nuovo gruppo di campi -->
                        <div class="col-12 mt-1 text-center">
                            <button class="btn btn-outline-dark" (click)="addChronology()">
                                <span class="me-2">Aggiungi Cronologia</span>
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>
                    </div>
                </form>

                <!-- Pulsante di Salvataggio -->
                <div class="row justify-content-center mt-4 borderTop">
                    <div *ngIf="chronologyForm.invalid && submitted" class="col-12 mt-2 text-center">
                        <small>Compilare correttamente tutti i campi obbligatori per abilitare il
                            salvataggio
                        </small>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mt-1 text-center">
                        <button class="btn btn-outline-dark" (click)="save()">
                            Salva
                        </button>
                    </div>
                </div>
            </div>

            <!-- <div class="container-fluid p-1 p-lg-2">
                <form [formGroup]="chronologyForm">
                    <div class="row justify-content-start align-items-center">
                        <div class="col-12 mt-2 text-center">
                            <span class="subTitle">Cronologia Generica</span>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 mt-3">
                            <label for="original_location" class="form-label" style="font-weight: bold;">
                                Ubicazione Originaria
                            </label>
                            <select class="form-select mb-2" aria-label="Default select example"
                                formControlName="original_location">
                                <option [ngValue]="null">Seleziona una categoria</option>
                                <option *ngFor="let option of ubicationOptions" [ngValue]="option.id"
                                    class="form-control">
                                    {{option.title}}
                                </option>
                            </select>
                        </div>
                    </div> -->
        </mat-expansion-panel>
    </mat-accordion>
</div>