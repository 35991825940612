<div class="container-fluid" style="min-width: 350px; margin-top: 66px;">
  <div class="row">
    <div class="col-12 mx-0 px-0">
      <div #myCarousel class="carousel slide carousel-fade pb-1">
        <div class="carousel-inner">
          <div class="container container_bar">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <span class="title">Cerca nell'archivio della Misericordia di Borgo San Lorenzo</span>
              </div>
              <div class="col-12 col-lg-8">
                <div class="input-group mb-3" style="height: 50px;">
                  <button class="btn btn-dark searchButton dropdown-toggle d-none d-md-block" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false" style="width: 158px;">
                    {{choosenArchive.name}}
                  </button>
                  <button class="btn btn-dark searchButton dropdown-toggle d-block d-md-none" type="button"
                    data-bs-toggle="dropdown" aria-expanded="false"
                    style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;">
                  </button>
                  <ul class="dropdown-menu">
                    <li class="listMenu" (click)="changeArchive()"><a class="dropdown-item">Archivio Storico</a></li>
                    <li class="listMenu" (click)="changeArchive()"><a class="dropdown-item">Archivio Artistico</a></li>
                  </ul>
                  <input type="text" class="form-control searchBar" style="opacity: 0.9;"
                    (keydown.enter)="searchArchive()" placeholder="Ricerca in {{choosenArchive.name}}"
                    [formControl]="search">
                  <button class="btn btn-dark searchButton" type="button" id="button-addon2"
                    (click)="searchArchive()">Cerca</button>
                </div>
              </div>
            </div>
          </div>
          <div *ngFor="let item of carousel; let i = index">
            <div class="carousel-item" [class.active]="i === 0">
              <img [src]="item.name" class="d-block w-100" oncontextmenu="return false;">
            </div>
          </div>
        </div>

        <!-- <div class="d-none d-lg-block">
          Freccia sinistra (prev)
          <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev"
            style="z-index: 1100;">
            <span class="carousel-control-prev-icon"></span>
            <span class="visually-hidden">Previous</span>
          </button>

          Freccia destra (next)
          <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next"
            style="z-index: 1100;">
            <span class="carousel-control-next-icon"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div> -->
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" style="min-width: 350px; max-width: 1800px;">
  <div class="row align-items-center">
    <div *ngFor="let card of cards; let i=index;" class="col-12 col-md-6 col-xxl-3 pt-1 pb-2 px-xxl-2">
      <app-card [obj_card]="card" [counter]="i+1" [thumbnails]="thumbnails"></app-card>
    </div>
  </div>
</div>


<div *ngIf="authService.isLoggedIn() && (canEditArticle$ | async)" class="container-fluid"
  style="min-width: 350px; max-width: 1800px;">
  <div class="row">
    <div class="col-12 text-center mb-2">
      <button class="btn btn-outline-dark" (click)="openPopup()">
        Modifica Visualizzazione Schede
      </button>
    </div>
  </div>
</div>

<div class="container-fluid" style="min-width: 350px;">
  <div class="row align-items-center py-4" style="background-color: #1E73BE;">
    <div class="col-12 col-sm-6 col-md-3 mt-2 text-center" style="height: 150px;">
      <a class="text-decoration-none" href="https://www.estotemisericordes.it/">
        <img class="navbar-logo redirect-logo" src="assets/img/estote.png">
        <div class="mt-2">
          <div style="color: white; font-weight: bold;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
            Fondazione Estote <br> Misericordes
          </div>
        </div>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mt-2 text-center" style="height: 150px;">
      <a class="text-decoration-none" href="http://www.misericordia.net/">
        <img class="navbar-logo redirect-logo" src="assets/img/logo.png">
        <div class="mt-2">
          <div class="justify-content-center" style="color: white; font-weight: bold; text-decoration: solid;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
            Misericordia <br> Borgo San Lorenzo
          </div>
        </div>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mt-2 text-center" style="height: 150px;">
      <a class="text-decoration-none" href="https://www.fratres.it/">
        <img class="navbar-logo redirect-logo" src="assets/img/fratres.png">
        <div class="mt-2">
          <div style="color: white; font-weight: bold;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
            Fratres <br> Donatori di sangue
          </div>
        </div>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-3 mt-2 text-center" style="height: 150px;">
      <a class="text-decoration-none" href="http://protezionecivile.uc-mugello.fi.it/">
        <img class="navbar-logo redirect-logo" src="assets/img/protezioneCivile.png">
        <div class="mt-2">
          <div class="text-decoration-none" style="color: white; font-weight: bold;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
            Protezione Civile <br> Mugello
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
