import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-location-data-readonly',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './location-data-readonly.component.html',
  styleUrl: './location-data-readonly.component.scss'
})
export class LocationDataReadonlyComponent {

  @Input() locationData: any = null;

}
