<div class="container p-0 p-sm-1 p-md-2 p-lg-3"
    style="min-height: 90vh; min-width: 350px; max-width: 1400px; margin-top: 66px;">
    <div class="row p-0 p-sm-1 p-md-2 justify-content-center align-items-center"
        style="margin-right: 0 !important; margin-left: 0 !important;">
        <!-- INTESTAZIONE PAGINA -->
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 63%; left: 40%;">
                INVENTARIO STORICO
            </span>
        </div>
        <div class="col-8 text-center pt-1">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    INVENTARIO STORICO
                </span>
            </div>
        </div>

        <!-- FILTRI -->
        <div class="col-12 col-sm-10 col-lg-8 p-3 p-lg-4">
            <div class="row justify-content-center d-none d-md-flex cardStyle p-2">
                <div class="col-6 mb-3">
                    <label for="categoryFilter mb-2" style="font-weight: bold; font-size: large;">Categorie</label>
                    <select class="form-select" id="categoryFilter" [formControl]="categoryFilter"
                        (change)="getSubcategories()">
                        <option [ngValue]="null" selected>Tutte le Categorie</option>
                        <option *ngFor="let category of categories" [ngValue]="category.id">
                            {{category.title}}
                        </option>
                    </select>
                </div>

                <div class="col-6 mb-3">
                    <label for="subcategoryFilter mb-2"
                        style="font-weight: bold; font-size: large;">Sottocategorie</label>
                    <select class="form-select" id="subcategoryFilter" [formControl]="subcategoryFilter">
                        <option [ngValue]="null" selected>--</option>
                        <option *ngFor="let subcategory of subcategories" [ngValue]="subcategory.id">
                            {{subcategory.title}}
                        </option>
                    </select>
                </div>
                <ng-container *ngIf="authService.isLoggedIn() && (canViewFieldsArticle$ | async)">
                    <div class="col-4 mb-3">
                        <label for="rackFilter mb-2" style="font-weight: bold; font-size: large;">
                            Scaffale
                        </label>
                        <input class="form-control" id="rackFilter" [formControl]="rackFilter">
                    </div>
                    <div class="col-4 mb-3">
                        <label for="shelfFilter mb-2" style="font-weight: bold; font-size: large;">
                            Ripiano
                        </label>
                        <input class="form-control" id="shelfFilter" [formControl]="shelfFilter">
                    </div>
                    <div class="col-4 mb-3">
                        <label for="caseFilter mb-2" style="font-weight: bold; font-size: large;">
                            Contenitore
                        </label>
                        <input class="form-control" id="caseFilter" [formControl]="caseFilter">
                    </div>
                </ng-container>

                <div class="col-12 text-center">
                    <button class="btn btn-outline-dark" (click)="getInventoryFromServer(true)">
                        Filtra
                    </button>
                </div>
            </div>

            <div class="row d-md-none">
                <div class="col-12">
                    <label for="categoryFilter mb-2" style="font-weight: bold; font-size: large;">Categorie</label>
                    <select class="form-select" id="categoryFilter" [formControl]="categoryFilter"
                        (change)="getInventoryFromServer(false)">
                        <option [ngValue]="null" selected>Tutte le Categorie</option>
                        <option *ngFor="let category of categories" [ngValue]="category.id">
                            {{category.title}}
                        </option>
                    </select>
                </div>
            </div>

        </div>

        <!-- LISTA ARTICOLI -->
        <div *ngFor="let element of inventoryHistorical" class="col-12 col-md-10 pt-3">
            <div class="titleBorder ps-3 d-flex justify-content-between align-items-center"
                (click)="openClose(element)">
                <div class="d-flex align-items-center">
                    <i class="bi bi-folder me-2"></i>
                    <span class="category">{{element.title}}</span>
                    <i *ngIf="(element.isOpen && element.listArticles.length)" class="bi bi-caret-up-fill ms-2"></i>
                    <i *ngIf="(!element.isOpen && element.listArticles.length)" class="bi bi-caret-down-fill ms-2"></i>
                </div>
                <span class="me-2" style="color: gray; font-style: italic; font-size: small;">Totale:
                    {{element.listArticles.length}}</span>
            </div>
            <div [ngClass]="{'open': element.isOpen, 'closed': !element.isOpen}"
                class="content-wrapper row ps-sm-3 pe-sm-3 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5">
                <div *ngFor="let article of element.listArticles" class="col-12 contentBorder">
                    <button class="btn btn-link" (click)="goToArticle(article.id)">
                        <span>{{article.title}}</span>
                    </button><br>
                    <span class="ms-4" matTooltip="Sottocategoria">{{article.subcategory}}</span>
                    <span *ngIf="authService.isLoggedIn() && (canViewFieldsArticle$ | async)">
                        <span matTooltip="Codice Articolo"> - {{article.code}},</span>
                        <span matTooltip="Luogo di Conservazione">
                            {{article.storage_place}},
                        </span>
                        <span matTooltip="Piano">
                            {{article.floor}},
                        </span>
                        <span matTooltip="Stanza">
                            {{article.room}},
                        </span>
                        <span matTooltip="Scaffale">
                            {{article.rack}},
                        </span>
                        <span matTooltip="Ripiano">
                            {{article.shelf}},
                        </span>
                        <span matTooltip="Contenitore">
                            {{article.case}}
                        </span>
                    </span>

                </div>
            </div>
        </div>
    </div>
</div>
